import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { getUserProfile } from './redux/Actions/memberActionCreater';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import EmailVerifyPage from './pages/EmailVerifyPage';
import TwoFAPage from './pages/TwoFAPage';
import ForgetPasswordPage from './pages/ForgetPasswordPage';
import PortfolioPage from './pages/PortfolioPage';
// import PlansPage from './pages/PlansPage';
// import NewsPage from './pages/NewsPage';
// import AccountPage from './pages/AccountPage';
import NotFoundPage from './pages/NotFoundPage';

import { GlobalStyle, ResetStyle } from './styles/globalStyle';

import {
  getWalletsBalance,
  getVaultsInfo,
  // setProjectsWithBalance,
  getProjects,
} from './redux/Actions/walletActionCreater';
import { getAllTokenPrice } from './redux/Actions/marketActionCreater';
import {
  getGLobalConfig,
  // getTxHistories,
  getDepositTxHistories,
} from './redux/Actions/paymentActionCreater';

function App() {
  const isLogined = useSelector((state) => state.member.login.isLogined);
  const profileIsFetching = useSelector((state) => state.member.profile.isFetching);
  const profileIsFetched = useSelector((state) => state.member.profile.isFetched);

  const authToken = useSelector((state) => state.member.token);

  const [cookies, setCookie] = useCookies(['Token']);

  const dispatch = useDispatch();

  const handle = () => {
    setCookie('Token', authToken, { path: '/', maxAge: 86400 });
  };

  useEffect(() => {
    if (authToken) {
      handle();
    }
  }, [authToken]);

  const getDashBoardData = (token) => {
    dispatch(getUserProfile(token));
    dispatch(getWalletsBalance(token));
    dispatch(getAllTokenPrice(token));
    dispatch(getVaultsInfo(token));
    dispatch(getProjects(token));
    dispatch(getGLobalConfig(token));
    dispatch(getDepositTxHistories(token, 'deposit'));
    dispatch(getDepositTxHistories(token, 'withdraw'));
    dispatch(getDepositTxHistories(token, 'yield'));
    dispatch(getDepositTxHistories(token, 'swap'));
  };

  useEffect(() => {
    if (authToken) {
      getDashBoardData(authToken);
    } else if (cookies.Token) {
      getDashBoardData(cookies.Token);
    }
  }, [authToken]);

  const redirectFromRoot = () => {
    let action;
    if (profileIsFetched && isLogined) {
      action = <Redirect to={{ pathname: '/portfolio' }} />;
    } else if ((profileIsFetched && !isLogined) || (!profileIsFetched && !cookies.Token)) {
      action = <Redirect to={{ pathname: '/login' }} />;
    }
    return action;
  };

  return (
      <Router>
          <ResetStyle />
          <GlobalStyle />
          <Container>
              {!profileIsFetching && (
              <Switch>
                  <Route
                      exact
                      path='/'>
                      {redirectFromRoot}
                  </Route>
                  <PublicRoute
                      exact
                      path='/login'
                      component={LoginPage}
                      isLogined={isLogined}
                      isFetched={profileIsFetched} />
                  <PublicRoute
                      exact
                      path='/register'
                      component={RegisterPage}
                      isLogined={isLogined}
                      isFetched={profileIsFetched} />
                  <PublicRoute
                      exact
                      path='/emailVerify/:nonce'
                      component={EmailVerifyPage}
                      isLogined={isLogined}
                      isFetched={profileIsFetched} />
                  <PublicRoute
                      exact
                      path='/twoFAVerify'
                      component={TwoFAPage}
                      isLogined={isLogined}
                      isFetched={profileIsFetched} />
                  <PublicRoute
                      exact
                      path='/forgetPassword'
                      component={ForgetPasswordPage}
                      isLogined={isLogined}
                      isFetched={profileIsFetched} />
                  <PrivateRoute
                      path='/portfolio'
                      component={PortfolioPage}
                      isLogined={isLogined}
                      isFetched={profileIsFetched} />
                  {/* <PrivateRoute path="/plans" component={PlansPage} />
            <PrivateRoute path="/news" component={NewsPage} />
            <PrivateRoute path="/account" component={AccountPage} /> */}
                  <Route component={NotFoundPage} />
              </Switch>
        )}
          </Container>
      </Router>
  );
}

export default App;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;
