import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { Line } from 'react-chartjs-2';
import Colors from '../styles/Colors';

const LineChart = (props) => {
  const { data } = props;

  const options = {
    maintainAspectRatio: false,
    tooltips: {
      callbacks: {
        label: (item) => `${item.yLabel}%`,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            // stepSize: 0.5,
            callback: (value) => `${value}%`,
          },
          gridLines: {
            color: Colors.gray3,
          },
        },
      ],
    },
  };

  const legend = {
    display: false,
  };

  return (
      <LineChartContainer>
          <Line
              data={data}
              options={options}
              legend={legend}
              responsive />
      </LineChartContainer>
  );
};

LineChart.propTypes = {
  data: PropTypes.object,
};

LineChart.defaultProps = {
  data: {},
};

export default LineChart;

const LineChartContainer = styled.div`
  position: relative;
  margin-top: 16px;
  height: 220px;
`;
