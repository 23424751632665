import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import { H5, H2, SmallBold } from '../../styles/Fonts';
import Loader from '../../components/Loader';

const WalletBalance = (props) => {
  const {
    totalBalance,
    projectBalance,
    // projectProfit,
    dataIsFetched,
  } = props;

  return (
      <Container>
          <Title>Total Wallet Balance</Title>
          {dataIsFetched ? (
              <>
                  <Balance>{`USD$ ~ ${totalBalance ? totalBalance.toLocaleString() : 0}`}</Balance>
                  <RowContainer>
                      <RowTitle>{'Total Investment Value in Yield Products'}</RowTitle>
                      <RowValue>{`USD$ ~ ${projectBalance.toLocaleString() || ''}`}</RowValue>
                  </RowContainer>
                  {/* <RowContainer>
                      <RowTitle>Profits</RowTitle>
                      <RowValue>{`USD$ ~ ${projectProfit.toLocaleString() || ''}`}</RowValue>
                  </RowContainer> */}
              </>
      ) : (
          <Loader isLoading={!dataIsFetched} />
      )}
      </Container>
  );
};

WalletBalance.propTypes = {
  totalBalance: PropTypes.number,
  projectBalance: PropTypes.number,
  projectProfit: PropTypes.number,
  dataIsFetched: PropTypes.bool,
};

WalletBalance.defaultProps = {
  totalBalance: 0,
  projectBalance: 0,
  projectProfit: 0,
  dataIsFetched: false,
};

export default WalletBalance;

const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
`;

const Title = styled(H5)`
  font-weight: 600;
`;

const Balance = styled(H2)`
  margin-top: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
`;

const RowTitle = styled(SmallBold)`
  width: 50%;
`;

const RowValue = styled(RowTitle)`
  text-align: right;
`;
