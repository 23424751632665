import {
  GET_TOKEN_LIST,
  GET_GLOBAL_CONFIG,
  GET_TXHISTORIES_START,
  GET_TXHISTORIES_SUCCEED,
  GET_TXHISTORIES_FAILED,
  GET_DEPOSIT_TXHISTORIES_START,
  GET_DEPOSIT_TXHISTORIES_SUCCEED,
  GET_DEPOSIT_TXHISTORIES_FAILED,
  GET_WITHDRAW_TXHISTORIES_START,
  GET_WITHDRAW_TXHISTORIES_SUCCEED,
  GET_WITHDRAW_TXHISTORIES_FAILED,
  GET_YIELD_TXHISTORIES_START,
  GET_YIELD_TXHISTORIES_SUCCEED,
  GET_YIELD_TXHISTORIES_FAILED,
  GET_SWAP_TXHISTORIES_START,
  GET_SWAP_TXHISTORIES_SUCCEED,
  GET_SWAP_TXHISTORIES_FAILED,
} from './paymentActionType';
import { cappuuGetGlobalConfig, cappuuGetTxHistories } from '../../utils/api';

const CIRCLE_ADDRESS = process.env.REACT_APP_CIRCLE_ADDRESS;

export const getGLobalConfig = (token) => async (dispatch) => {
  try {
    const data = await cappuuGetGlobalConfig(token);
    const tokens = JSON.parse(JSON.parse(data.token_configs));

    dispatch({ type: GET_TOKEN_LIST, payload: tokens });
    dispatch({ type: GET_GLOBAL_CONFIG, payload: { data } });
  } catch (error) {
    console.log(error);
  }
};

export const getTxHistories = (token, txType) => async (dispatch) => {
  dispatch({ type: GET_TXHISTORIES_START });
  try {
    const txHistories = await cappuuGetTxHistories(token, txType);
    dispatch({
      type: GET_TXHISTORIES_SUCCEED,
      payload: {
        txHistories: txHistories.filter(
          (tx) => !(
            tx.tx_type === 'gov_bonus'
              || (tx.tx_type === 'external'
                && (tx.from_address.toLowerCase() === tx.owner_info.eth_address.toLowerCase()
                  || tx.from_address.toLowerCase() === CIRCLE_ADDRESS.toLowerCase()))
          ),
        ),
      },
    });
  } catch (error) {
    dispatch({ type: GET_TXHISTORIES_FAILED, payload: { error } });
  }
};

export const getDepositTxHistories = (token, txType) => async (dispatch) => {
  const startReducers = {
    deposit: GET_DEPOSIT_TXHISTORIES_START,
    withdraw: GET_WITHDRAW_TXHISTORIES_START,
    yield: GET_YIELD_TXHISTORIES_START,
    swap: GET_SWAP_TXHISTORIES_START,
  };
  const succeedReducers = {
    deposit: GET_DEPOSIT_TXHISTORIES_SUCCEED,
    withdraw: GET_WITHDRAW_TXHISTORIES_SUCCEED,
    yield: GET_YIELD_TXHISTORIES_SUCCEED,
    swap: GET_SWAP_TXHISTORIES_SUCCEED,
  };
  const failedReducers = {
    deposit: GET_DEPOSIT_TXHISTORIES_FAILED,
    withdraw: GET_WITHDRAW_TXHISTORIES_FAILED,
    yield: GET_YIELD_TXHISTORIES_FAILED,
    swap: GET_SWAP_TXHISTORIES_FAILED,
  };

  dispatch({ type: startReducers[txType] });
  try {
    const txHistories = await cappuuGetTxHistories(token, txType);
    dispatch({
      type: succeedReducers[txType],
      payload: txHistories.filter(
        (tx) => !(
          tx.tx_type === 'gov_bonus'
            || (tx.tx_type === 'external'
              && (tx.from_address.toLowerCase() === tx.owner_info.eth_address.toLowerCase()
                || tx.from_address.toLowerCase() === CIRCLE_ADDRESS.toLowerCase()))
        ),
      ),
    });
  } catch (error) {
    dispatch({ type: failedReducers[txType], payload: { error } });
  }
};
