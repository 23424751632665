/* eslint-disable max-len */
export const cappuuLogin = async (email, password, totpCode) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/login`;

  const form = new FormData();
  form.append('username', email);
  form.append('password', password);
  form.append('totp_code', totpCode || '');

  const options = {
    method: 'post',
    body: form,
  };

  const result = await fetch(url, options);
  const resultJson = await result.json();

  if (result.status === 200) {
    return resultJson.data;
  }

  throw new Error(resultJson.errors[0].message);
};

export const cappuuRegister = async (name, email, password, inviteCode = '') => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/register`;

  // Create form
  const form = new FormData();
  form.append('name', name);
  form.append('email_address', email);
  form.append('password', password);
  if (inviteCode !== '') {
    form.append('invite_code', inviteCode);
  }

  // CALL API
  const options = {
    method: 'post',
    body: form,
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const cappuuVerifyEmail = async (nonce, code) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/sms/${nonce}/verify`;

  // Create form
  const form = new FormData();
  form.append('code', code);

  // Call API
  const options = {
    method: 'post',
    body: form,
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error('Verify Failed');
};

export const cappuuGetUserProfile = async (token) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/members/profile`;
  // Call API
  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const cappuuGetWalletsBalance = async (token) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/members/balance`;

  // Call API
  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const cappuuGetVaults = async (token) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/yield_products`;
  const options = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const cappuuGetAllTokenPrice = async (token) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/swap/prices/list`;

  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    // return resultJson.data;
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const coinomoGetWalletTx = async (authToken, currency) => {
  const url = `${
    process.env.REACT_APP_CAPPUU_API
  }/wallets/transactions?currency=${currency.toLowerCase()}&tx_type=cash_in&tx_type=cash_out&tx_type=wallet&tx_type=swap&per_page=50&page_number=1`;

  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${authToken}`,
    },
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const coinomoGetCashInTx = async (authToken, currency) => {
  const url = `${
    process.env.REACT_APP_CAPPUU_API
  }/yield_products/transactions/transactions?dest_wallet_currency=${currency.toLowerCase()}&per_page=50&page_number=1`;

  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${authToken}`,
    },
  };
  const result = await fetch(url, options);
  const resultJson = await result.json();

  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const cappuuGetGlobalConfig = async (token) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/global_config`;

  const options = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const result = await fetch(url, options);

  const resultJson = await result.json();

  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const cappuuGetActualApy = async (token, vaultId, period, dataAmount) => {
  const url = `${process.env.REACT_APP_CAPPUU_API}/yield_products/apy?product_id=${vaultId}&period=${period}&data_amount=${dataAmount}`;

  const options = {
    method: 'get',
    headers: {
      Authorization: `Token ${token}`,
    },
  };

  const result = await fetch(url, options);

  const resultJson = await result.json();

  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};

export const cappuuGetTxHistories = async (token, txType) => {
  const route = {
    deposit: '/cashflows/payments',
    withdraw: '/relayer/transactions',
    yield: '/yield_products/transactions',
    swap: '/swap/transactions',
  };
  const txTypes = {
    deposit: '',
    withdraw: '&tx_type=withdraw_from_wallet',
    yield: '&tx_type=deposit&tx_type=withdraw',
    swap: '',
  };
  // eslint-disable-next-line max-len
  const url = `${process.env.REACT_APP_CAPPUU_API}${route[txType]}?per_page=100&page_number=1${txTypes[txType]}`;
  // Call API
  const options = {
    headers: {
      Authorization: `Token ${token}`,
    },
  };
  const result = await fetch(url, options);

  const resultJson = await result.json();
  // Succeed
  if (result.status === 200) {
    return resultJson.data;
  }
  // Failed
  throw new Error(resultJson.errors[0].message);
};
