export const GET_SWAP_PRICE_START = 'GET_SWAP_PRICE_START';
export const GET_SWAP_PRICE_SUCCEED = 'GET_SWAP_PRICE_SUCCEED';
export const GET_SWAP_PRICE_FAILED = 'GET_SWAP_PRICE_FAILED';
export const SWAP_TOKEN_START = 'SWAP_TOKEN_START';
export const SWAP_TOKEN_SUCCEED = 'SWAP_TOKEN_SUCCEED';
export const SWAP_TOKEN_FAILED = 'SWAP_TOKEN_FAILED';
export const RESET_SWAP_TOKEN_ERROR = 'RESET_SWAP_TOKEN_ERROR';
export const GET_ALL_TOKEN_PRICE = 'GET_ALL_TOKEN_PRICE';
export const GET_K_LINE_START = 'GET_K_LINE_START';
export const GET_K_LINE_SUCCEED = 'GET_K_LINE_SUCCEED';
export const GET_K_LINE_FAILED = 'GET_K_LINE_FAILED';
export const GET_WITHDRAW_INFO = 'GET_WITHDRAW_INFO';
export const GET_TOKEN_HISTORY = 'GET_TOKEN_HISTORY';
export const UPDATE_TOKEN_SWAP_PRICE = 'UPDATE_TOKEN_SWAP_PRICE';
export const GET_TOKEN_DESCRIPTION = 'GET_TOKEN_DESCRIPTION';
export const GET_DEPOSIT_CHAIN = 'GET_DEPOSIT_CHAIN';
export const GET_DEPOSIT_ADDRESS = 'GET_DEPOSIT_ADDRESS';
export const GET_DEPOSIT_INFO_START = 'GET_DEPOSIT_INFO_START';
export const GET_DEPOSIT_INFO_FAILED = 'GET_DEPOSIT_INFO_FAILED';
export const GET_ALL_TOKEN_K_LINE = 'GET_ALL_TOKEN_K_LINE';
