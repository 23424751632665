import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { loginMember } from '../../redux/Actions/memberActionCreater';
import { validateEmail, validatePassword } from '../../utils/validates';

import CoinomoLogo from '../../images/coinomo.png';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { TinyRegular, H5 } from '../../styles/Fonts';
import Colors from '../../styles/Colors';
import { ErrorText } from '../../styles/Fonts';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const errorMsg = useSelector((state) => state.member.login.errorMsg);
  const isLogined = useSelector((state) => state.member.login.isLogined);
  const twoFAIsEnabled = useSelector((state) => state.member.login.twoFAIsEnabled);

  const history = useHistory();
  const dispatch = useDispatch();

  const login = () => {
    const emailChecked = validateEmail(email, setEmailIsValid);
    const passwordChecked = validatePassword(password, setPasswordIsValid);
    if (emailChecked && passwordChecked) {
      dispatch(loginMember(email, password));
    }
  };

  const register = () => {
    history.push('/register');
  };

  useEffect(() => {
    if (isLogined) {
      history.push('/portfolio');
    } else if (twoFAIsEnabled) {
      history.push({
        pathname: '/twoFAVerify',
        state: { email, password },
      });
    }
  }, [isLogined, twoFAIsEnabled]);

  return (
      <Container>
          <MainContent>
              <RowContainer>
                  <Image src={CoinomoLogo} />
                  <Beta>Beta</Beta>
              </RowContainer>
              <Title>Login</Title>
              <TextInput
                  type='email'
                  onChange={setEmail}
                  validationFailed={!emailIsValid} />
              <TextInput
                  type='password'
                  onChange={setPassword}
                  validationFailed={!passwordIsValid} />
              {!!errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
              <Button
                  label='Login'
                  type='primary'
                  callback={login} />
              <Button
                  label='Register'
                  type='secondary'
                  callback={register} />
          </MainContent>
          <Version>Version 1.06</Version>
      </Container>
  );
};

export default LoginPage;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const MainContent = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 32px 16px;
  border-radius: 8px;
`;

const Image = styled.img`
  width: 170px;
  height: 32px;
`;

const Title = styled(H5)`
  margin-top: 16px;
`;

const ErrorMsg = styled(ErrorText)`
  margin-top: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Beta = styled(TinyRegular)`
  margin-top: 6px;
  margin-left: 8px;
  color: ${Colors.gray1};
  background: ${Colors.blue};
  border-radius: 30px;
  padding: 3px 9px;
`;

const Version = styled(TinyRegular)`
  margin-top: 20px;
  color: ${Colors.gray4};
  position: absolute;
  bottom: 10px;
  left: 10px;
`;
