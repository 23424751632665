/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import { H5 } from '../../styles/Fonts';
import NoRecord from '../../components/NoRecord';
import { getActualApy } from '../../redux/Actions/walletActionCreater';
import LineChart from '../../components/LineChart';
import Colors from '../../styles/Colors';
import Loader from '../../components/Loader';

const ApyPerformance = (props) => {
  const { token, projectsWithBalance } = props;
  const actualApy = useSelector((state) => state.wallet.actualApy);
  const actualApyIsFetched = useSelector((state) => state.wallet.actualApyIsFetched);

  const [projects, setProjects] = useState([]);
  const [projectsNotExist, setProjectsNotExist] = useState(false);
  const [selectedProject, setSelectedProject] = useState({ name: '', id: '' });
  const [lineChartData, setLineChartData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (projects.length === 0) {
      // eslint-disable-next-line react/prop-types
      const filteredProjectsWithBalance = projectsWithBalance.filter((project) => project.balance > 0);
      const projectList = filteredProjectsWithBalance.map((project) => ({
        name: project.name,
        id: project.info.product_id,
        num: Math.random(),
      }));
      setProjects(projectList);
    }
  }, [projectsWithBalance]);

  useEffect(() => {
    if (projectsWithBalance.length > 0 && projects.length > 0) {
      setSelectedProject({ name: projects[0].name, id: projects[0].id });
    } else if (projectsWithBalance.length > 0 && projects.length === 0) {
      setProjectsNotExist(true);
    }
  }, [projects]);

  const onSelect = (name, id) => {
    if (id !== selectedProject.id) {
      setSelectedProject({ name, id });
    }
  };

  useEffect(() => {
    if (selectedProject.id) {
      dispatch(getActualApy(token, selectedProject.id, 'weekly', 10));
    }
  }, [selectedProject]);

  const getDateLabel = () => {
    const dateLabel = actualApy.map((apy) => {
      const dateArray = apy.to_time.split('-');
      const formatedDate = `${dateArray[1]}/${dateArray[2]}`;
      return formatedDate;
    });
    // remove today's apy data
    return dateLabel;
  };

  const getApyList = () => {
    const apys = actualApy.map((apy) => ((parseFloat(apy.apy) - 1) * 100).toFixed(2));
    // remove today's apy data
    return apys;
  };

  useEffect(() => {
    if (actualApy.length > 0) {
      const dates = getDateLabel();
      const apys = getApyList();
      const lineData = {
        labels: dates,
        datasets: [
          {
            label: 'APY %',
            data: apys,
            fill: false,
            backgroundColor: Colors.primary.default,
            borderColor: Colors.primary.default,
            marginTop: '20px',
            tension: 0,
          },
        ],
      };
      setLineChartData(lineData);
    }
  }, [actualApy]);

  return (
      <Container>
          <RowContainer>
              <Title>Weekly APY Performance</Title>
              <ProjectDropDown>
                  <Toggle disabled={!actualApyIsFetched}>{selectedProject.name || 'Select Project'}</Toggle>
                  <DropdownMenu>
                      {projects.map((project) => (
                          <Item
                              key={project.num}
                              isActive={project.name === selectedProject.name}
                              onClick={() => onSelect(project.name, project.id)}>
                              {project.name}
                          </Item>
            ))}
                  </DropdownMenu>
              </ProjectDropDown>
          </RowContainer>
          {actualApyIsFetched && <LineChart data={lineChartData} />}
          {!actualApyIsFetched && !projectsNotExist && <Loader isLoading />}
          {projectsNotExist && <NoRecord />}
      </Container>
  );
};

ApyPerformance.propTypes = {
  token: PropTypes.string,
  projectsWithBalance: PropTypes.array,
};

ApyPerformance.defaultProps = {
  token: '',
  projectsWithBalance: [],
};

export default ApyPerformance;

const Container = styled.div`
  width: 100%;
  background-color: ${Colors.gray1};
  padding: 16px;
  border-radius: 8px;
`;

const Title = styled(H5)`
  font-weight: 600;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

const ProjectDropDown = styled(Dropdown)`
  .btn-primary.dropdown-toggle {
    color: ${Colors.gray6};
    background-color: ${Colors.gray1};
    border: 1px solid ${Colors.gray3};
    font-size: 14px;
    line-height: 22px;
  }
  .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
  }
  @media (max-width: 576px) {
    margin-top: 16px;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  width: 240px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const Toggle = styled(Dropdown.Toggle)`
  width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const Item = styled(Dropdown.Item)`
  color: ${(props) => (props.isActive ? Colors.primary.default : Colors.gray6)};
  font-size: 14px;
  line-height: 22px;
  :active {
    background-color: ${Colors.gray4};
  }
`;
