import React, { useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import Colors from '../../styles/Colors';

import { SmallRegular, SmallBold } from '../../styles/Fonts';

import down from '../../images/chevron_down.png';

const VaultCard = (props) => {
  const { project } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const balance = project.balance;
  const depositBalance = project.totalDeposit;
  const withdrawBalance = project.totalWithdraw;
  const profit = balance - depositBalance + withdrawBalance;
  const currency = project.info?.token_currency.toUpperCase();
  const price = project.price;

  const getEstiAmount = (num) => {
    return num * price;
  };

  const renderDetail = (title, value) => {
    return (
        <>
            <Detail>
                <Title>{title}</Title>
                <Value>{`${value.toLocaleString()} ${currency}`}</Value>
            </Detail>
            {currency !== 'USDC' && (
            <EstiDetail>
                <EstiTitle>{`${title} measured in USD`}</EstiTitle>
                <EstiValue>{`${getEstiAmount(value).toLocaleString()} USD`}</EstiValue>
            </EstiDetail>
        )}
        </>
    );
  };

  const RenderHistoryDetail = (title, value) => {
    return (
        <>
            <Detail>
                <Title>{title}</Title>
                <HistoryValue>{`${value.toLocaleString()} ${currency}`}</HistoryValue>
            </Detail>
            {currency !== 'USDC' && (
            <EstiDetail>
                <EstiTitle>{`${title} measured in USD`}</EstiTitle>
                <EstiHistoryValue>{`${getEstiAmount(value).toLocaleString()} USD`}</EstiHistoryValue>
            </EstiDetail>
        )}
        </>
    );
  };

  return (
      <Project>
          <Header onClick={handleClick}>
              <SmallRegular>{project.name}</SmallRegular>
              <Icon
                  src={down}
                  isOpen={isOpen} />
          </Header>
          <Main isOpen={isOpen}>
              {project.info?.backend !== 'deribit' && (
              <>
                  <Balance>
                      {renderDetail('Current Balance', balance)}
                      {renderDetail('Total Profit', profit)}
                      <Detail>
                          <Title>ROI</Title>
                          <Value>{`${((profit / depositBalance) * 100).toLocaleString()}%`}</Value>
                      </Detail>
                  </Balance>
                  <History>
                      {RenderHistoryDetail('Total Deposit Amount', depositBalance)}
                      {RenderHistoryDetail('Total Withdraw Amount', withdrawBalance)}
                  </History>
              </>
        )}
              {project.info?.backend === 'deribit' && <Balance>{renderDetail('Current Balance', balance)}</Balance>}
          </Main>
      </Project>
  );
};

export default VaultCard;

VaultCard.propTypes = {
  project: PropTypes.object,
};

VaultCard.defaultProps = {
  project: {},
};

const Project = styled.div`
  height: auto;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${Colors.gray3};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Header = styled(Row)`
  cursor: pointer;
  align-items: center;
`;

const Main = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '600px' : '0px')};
  margin-top: ${(props) => (props.isOpen ? '12px' : '0px')};
  border-top-width: ${(props) => (props.isOpen ? '0.5px' : '0px')};
  border-top-style: solid;
  border-top-color: ${Colors.gray3};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: all 0.2s ease-in-out;
`;

const Balance = styled.div``;

const History = styled.div`
  border-top: 0.5px dashed ${Colors.gray3};
  margin-top: 12px;
`;

const Detail = styled(Row)`
  margin-top: 12px;
`;

const EstiDetail = styled(Detail)`
  margin-top: 2px;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  transform: ${(props) => (props.isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
`;

const Title = styled(SmallRegular)`
  width: 50%;
`;

const EstiTitle = styled(Title)`
  color: ${Colors.gray5};
`;

const Value = styled(SmallBold)`
  width: 50%;
  text-align: right;
`;

const EstiValue = styled(Value)`
  color: ${Colors.gray5};
`;

const HistoryValue = styled(SmallRegular)`
  text-align: right;
  color: ${Colors.gray6};
`;

const EstiHistoryValue = styled(HistoryValue)`
  color: ${Colors.gray5};
`;
