const LOGIN_MEMBER_START = 'LOGIN_MEMBER_START';
const LOGIN_MEMBER_SUCCEED = 'LOGIN_MEMBER_SUCCEED';
const LOGIN_MEMBER_FAILED = 'LOGIN_MEMBER_FAILED';
const REGISTER_MEMBER_START = 'REGISTER_MEMBER_START';
const REGISTER_MEMBER_SUCCEED = 'REGISTER_MEMBER_SUCCEED';
const REGISTER_MEMBER_FAILED = 'REGISTER_MEMBER_FAILED';
const VERIFY_MEMBER_EMAIL_START = 'VERIFY_MEMBER_EMAIL_START';
const VERIFY_MEMBER_EMAIL_SUCCEED = 'VERIFY_MEMBER_EMAIL_SUCCEED';
const VERIFY_MEMBER_EMAIL_FAILED = 'VERIFY_MEMBER_EMAIL_FAILED';
const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
const GET_USER_PROFILE_SUCCEED = 'GET_USER_PROFILE_SUCCEED';
const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED';
const LOGOUT_MEMBER = 'LOGOUT_MEMBER';
const CANNOT_GET_USER_PROFILE = 'CANNOT_GET_USER_PROFILE';
const BACK_TO_REGISTERPAGE = 'BACK_TO_REGISTERPAGE';
const LOGIN_MEMBER_NEED_2FA = 'LOGIN_MEMBER_NEED_2FA';

export {
  LOGIN_MEMBER_START,
  LOGIN_MEMBER_SUCCEED,
  LOGIN_MEMBER_FAILED,
  REGISTER_MEMBER_START,
  REGISTER_MEMBER_SUCCEED,
  REGISTER_MEMBER_FAILED,
  VERIFY_MEMBER_EMAIL_START,
  VERIFY_MEMBER_EMAIL_SUCCEED,
  VERIFY_MEMBER_EMAIL_FAILED,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCEED,
  GET_USER_PROFILE_FAILED,
  LOGOUT_MEMBER,
  CANNOT_GET_USER_PROFILE,
  BACK_TO_REGISTERPAGE,
  LOGIN_MEMBER_NEED_2FA,
};
