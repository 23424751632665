export const SET_CURRENT_WALLET = 'SET_CURRENT_WALLET';
export const CLEAN_CURRENT_WALLET = 'CLEAN_CURRENT_WALLET';
export const GET_WALLETS_BALANCE_START = 'GET_WALLETS_BALANCE_START';
export const GET_WALLETS_BALANCE_SUCCEED = 'GET_WALLETS_BALANCE_SUCCEED';
export const GET_WALLETS_BALANCE_FAILED = 'GEt_WALLETS_BALANCE_FAILED';
export const GET_FIREBASE_PROJECTS = 'GET_FIREBASE_PROJECTS';
export const GET_VAULTS_INFO = 'GET_VAULTS_INFO';
export const SET_PROJECTS_WITH_BALANCE = 'SET_PROJECTS_WITH_BALANCE';
export const START_GET_ACTUAL_APY = 'START_GET_ACTUAL_APY';
export const GET_ACTUAL_APY = 'GET_ACTUAL_APY';
