/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import NavItem from './NavItem';

import logoOnly from '../images/logo_only.png';
import logoText from '../images/logo_text.png';
import menu from '../images/menu.png';
import menuFold from '../images/menu_fold.png';
import menuUnfold from '../images/menu_unfold.png';
import help from '../images/help.png';
import close from '../images/close.png';
import logoutIcon from '../images/logout.png';
import { SmallRegular } from '../styles/Fonts';
import Colors from '../styles/Colors';

import useScrollListener from '../utils/hooks/useScrollListener';

const SideBar = (props) => {
  const { open, toggle, logout } = props;
  const [showNavbar, setShowNavbar] = useState(false);
  const [headerIshidden, setHeaderIsHidden] = useState(false);

  const navs = useRef(null);
  const scroll = useScrollListener();
  const history = useHistory();

  useEffect(() => {
    if (!showNavbar) return;
    function handleClick(event) {
      if (navs.current && !navs.current.contains(event.target)) {
        setShowNavbar(false);
      }
    }
    window.addEventListener('click', handleClick);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('click', handleClick);
  }, [showNavbar]);

  useEffect(() => {
    if (scroll.y > 56 && scroll.y - scroll.lastY > 0) {
      setHeaderIsHidden(true);
    } else {
      setHeaderIsHidden(false);
    }
    setShowNavbar(false);
  }, [scroll.y, scroll.lastY]);

  const onClickLogo = () => {
    history.push('/portfolio');
  };

  return (
      <Container>
          <HeaderContainer hide={headerIshidden}>
              <MenuContainer>
                  <Icon
                      src={menu}
                      onClick={() => setShowNavbar(true)} />
              </MenuContainer>
              <LogoContainer onClick={onClickLogo}>
                  <LogoIcon src={logoOnly} />
                  <LogoText
                      src={logoText}
                      open={open} />
              </LogoContainer>
              <HelpContainer
                  href={process.env.REACT_APP_CAPPUU_HELP_URL}
                  target='_blank'>
                  <Icon src={help} />
              </HelpContainer>
          </HeaderContainer>
          <NavContainer show={showNavbar}>
              <MainNavs>
                  <CloseContainer>
                      <Icon
                          src={close}
                          onClick={() => setShowNavbar(false)} />
                  </CloseContainer>
                  <NavItem
                      navTarget='portfolio'
                      open={open} />
                  <NavItem
                      navTarget='plans'
                      disabled
                      open={open} />
                  <NavItem
                      navTarget='news'
                      disabled
                      open={open} />
                  <NavItem
                      navTarget='account'
                      disabled
                      open={open} />
              </MainNavs>
              <Other>
                  <LogoutContainer onClick={logout}>
                      <LogoutIcon src={logoutIcon} />
                      <LogoutText open={open}>Logout</LogoutText>
                  </LogoutContainer>
                  <ToggleContainer onClick={toggle}>
                      <ToggleIcon src={open ? menuFold : menuUnfold} />
                      <ToggleText open={open}>Collapse Sidebar</ToggleText>
                  </ToggleContainer>
              </Other>
          </NavContainer>
          {showNavbar && <Mask />}
      </Container>
  );
};

export default SideBar;

SideBar.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
  logout: PropTypes.func,
};

SideBar.defaultProps = {
  open: false,
  toggle: () => {},
  logout: () => {},
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${Colors.gray1};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const HeaderContainer = styled.div`
  @media (max-width: 992px) {
    width: 100vw;
    height: 56px;
    background-color: ${Colors.gray1};
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 16px;
    transform: ${(props) => (props.hide ? 'translateY(-100%)' : null)};
    transition: transform 300ms ease-in-out;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: cetner;
  margin: 16px;
  cursor: pointer;
  @media (max-width: 992px) {
    margin: 0px;
  }
`;

const LogoIcon = styled.img`
  width: 32px;
  height: 32px;
  margin: auto 0;
`;

const LogoText = styled.img`
  width: 120.6px;
  height: 20.4px;
  margin: auto 0 auto 8px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all 0.5s ease;
  @media (max-width: 992px) {
    opacity: 1;
  }
`;

const NavContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 992px) {
    width: 240px;
    height: 100vh;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: ${Colors.gray1};
    width: ${(props) => (props.show ? '240px' : '0px')};
    overflow: hidden;
    /* opacity: ${(props) => (props.show ? 1 : 0)}; */
    transition: all 0.3s ease-in-out;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2px;
  cursor: pointer;
  overflow: hidden;
  @media (max-width: 992px) {
    display: none;
  }
`;

const ToggleIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 16px;
`;

const ToggleText = styled(SmallRegular)`
  color: ${Colors.gray5};
  opacity: ${(props) => (props.open ? 1 : 0)};
  white-space: nowrap;
  transition: all 0.5s ease;
`;

const LogoutContainer = styled(ToggleContainer)`
  @media (max-width: 992px) {
    display: flex;
    margin-bottom: 16px;
  }
`;

const LogoutIcon = styled(ToggleIcon)``;

const LogoutText = styled(ToggleText)`
  @media (max-width: 992px) {
    opacity: 1;
  }
`;

const MainNavs = styled.div``;

const Other = styled.div``;

const MenuContainer = styled.div`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const HelpContainer = styled.a`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const CloseContainer = styled.div`
  display: none;
  @media (max-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
  }
`;

const Mask = styled.div`
  @media (max-width: 992px) {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
  }
`;
