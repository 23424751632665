import {
  CREATE_PAYMENT_SET_AMOUNT,
  CREATE_PAYMENT_SET_CARDID,
  CREATE_PAYMENT_START,
  CREATE_PAYMENT_SUCCEED,
  CREATE_PAYMENT_FAILED,
  CREATE_CARD_SET_CARDINFO,
  CREATE_CARD_START,
  CREATE_CARD_SUCCEED,
  CREATE_CARD_FAILED,
  GET_CARD_START,
  GET_CARD_SUCCEED,
  GET_CARD_FAILED,
  GET_TXHISTORIES_START,
  GET_TXHISTORIES_SUCCEED,
  GET_TXHISTORIES_FAILED,
  GET_GLOBAL_CONFIG,
  GET_PAYMENT_CALCULATION,
  GET_PAYMENT_CALCULATION_START,
  GET_PAYMENT_CALCULATION_FAILED,
  SAVE_TEMP_BILLING_INFO,
  SEND_KYC_INFO_START,
  SEND_KYC_INFO_SUCCEED,
  SEND_KYC_INFO_FAILED,
  DELETE_CARD_START,
  DELETE_CARD_SUCCEED,
  DELETE_CARD_FAILED,
  CREATE_BANK_ACCOUNT_START,
  CREATE_BANK_ACCOUNT_SUCCEED,
  CREATE_BANK_ACCOUNT_FAILED,
  GET_BANK_ACCOUNT_START,
  GET_BANK_ACCOUNT_SUCCEED,
  GET_BANK_ACCOUNT_FAILED,
  GET_WIRE_INSTRUCTION_START,
  GET_WIRE_INSTRUCTION_SUCCEED,
  GET_WIRE_INSTRUCTION_FAILED,
  SAVE_TEMP_BANK_BILLING_INFO,
  GET_IDR_PAYMENT_START,
  GET_IDR_PAYMENT_SUCCEED,
  GET_IDR_PAYMENT_FAILED,
  CREATE_IDR_PAYMENT_START,
  CREATE_IDR_PAYMENT_SUCCEED,
  CREATE_IDR_PAYMENT_FAILED,
  CHECK_IDR_PAYMENT_START,
  CHECK_IDR_PAYMENT_SUCCEED,
  CHECK_IDR_PAYMENT_FAILED,
  GET_CASHFLOW_BACKENDS,
  CANCEL_PAYMENT_START,
  CANCEL_PAYMENT_SUCCEED,
  CANCEL_PAYMENT_FAILED,
  CLEAN_PAYMENT,
  SELECT_CARD,
  GET_SIMPLEX_CALCULATION_START,
  GET_SIMPLEX_CALCULATION_SUCCEED,
  GET_SIMPLEX_CALCULATION_FAILED,
  GET_SIMPLEX_URL_START,
  GET_SIMPLEX_URL_SUCCEED,
  GET_SIMPLEX_URL_FAILED,
  RESET_SIMPLEX,
  GET_BONUS_CONFIG,
  GET_VAULT_TX_START,
  GET_VAULT_TX_SUCCESS,
  GET_VAULT_TX_FAILED,
  GET_DEPOSIT_TXHISTORIES_START,
  GET_DEPOSIT_TXHISTORIES_SUCCEED,
  GET_DEPOSIT_TXHISTORIES_FAILED,
  GET_WITHDRAW_TXHISTORIES_START,
  GET_WITHDRAW_TXHISTORIES_SUCCEED,
  GET_WITHDRAW_TXHISTORIES_FAILED,
  GET_YIELD_TXHISTORIES_START,
  GET_YIELD_TXHISTORIES_SUCCEED,
  GET_YIELD_TXHISTORIES_FAILED,
  GET_SWAP_TXHISTORIES_START,
  GET_SWAP_TXHISTORIES_SUCCEED,
  GET_SWAP_TXHISTORIES_FAILED,
} from '../Actions/paymentActionType';

const InitialState = {
  cards: [], // List of user's cards TODO: persit
  txHistories: [], // List of user's payment history TODO: persit
  fee: 0,
  payments: [], // List of user's payment history TODO: persit
  config: null,
  getCards: {
    isCardFetching: false,
    isCardFetched: false,
    errorMsg: '',
  },
  getTxHistories: {
    isTxHistoriesFetching: false,
    isTxHistoriesFetched: false,
    errorMsg: '',
  },
  createCard: {
    cardInfo: {},
    ownerName: '',
    isCardCreating: false,
    isCardCreated: false,
    errorMsg: '',
  },
  createPayment: {
    amount: 0,
    cardId: '',
    isPaymentCreating: false,
    isPaymentCreated: false,
    errorMsg: '',
    calculatedAmount: 0,
    isPaymentCalculating: false,
  },
  paymentCalculation: {
    payAmount: 0,
    feeCashflow: 0,
    feeCappuu: 0,
    isPaymentCalculating: false,
    errorMsg: '',
  },
  billingInfo: {
    country: 'TW',
    district: 'MA',
    address: '',
    city: '',
    postalCode: '',
    phone: '',
  },
  kycInfo: {
    isSending: false,
    isSend: false,
    errorMsg: '',
  },
  deleteCard: {
    isDeleting: false,
    errorMsg: '',
  },
  createBankAccount: {
    isCreating: false,
    isCreated: false,
    errorMsg: '',
  },
  bankAccount: {
    isFetching: false,
    isFetched: false,
    errorMsg: '',
    account: [],
  },
  wireInstruction: {
    isFetching: false,
    isFetched: false,
    errorMsg: '',
    beneficiary: {
      address1: '',
      address2: '',
      name: '',
    },
    beneficiaryBank: {
      accountNumber: '',
      address: '',
      city: '',
      country: '',
      name: '',
      postalCode: '',
      routingNumber: '',
      swiftCode: '',
      trackingRef: '',
    },
  },
  tempBankBillingInfo: {
    city: '',
    country: 'TW',
    address: '',
    postalCode: '',
  },
  idrPayment: {
    isFetching: false,
    isFetched: false,
    payment: null,
    errorMsg: '',
  },
  createIdrPayment: {
    isCreating: false,
    isCreated: false,
    errorMsg: '',
  },
  checkIdrPayment: {
    isChecking: false,
  },
  cashflowBackends: {},
  cancelPayment: {
    isCanceling: false,
    isCanceled: false,
    errorMsg: '',
  },
  selectedCardIndex: 0,
  simplexPayment: {
    isCalculating: false,
    isCalculated: false,
    isGettingUrl: false,
    price: 0,
    quoteId: '',
    url: '',
    errorMsg: '',
  },
  bonusConfig: {
    displayName: '',
    usdRaito: 100,
  },
  vaultTx: {
    isFetching: false,
    isFetched: false,
    tx: [],
  },
  depositTx: {
    isFetching: false,
    isFetched: false,
    tx: [],
  },
  withdrawTx: {
    isFetching: false,
    isFetched: false,
    tx: [],
  },
  yieldTx: {
    isFetching: false,
    isFetched: false,
    tx: [],
  },
  swapTx: {
    isFetching: false,
    isFetched: false,
    tx: [],
  },
};

export default (state = InitialState, action) => {
  switch (action.type) {
    case CREATE_PAYMENT_SET_AMOUNT: {
      const { amount } = action.payload;
      return {
        ...state,
        createPayment: {
          amount,
          cardId: '',
          isPaymentCreating: false,
          isPaymentCreated: false,
          errorMsg: '',
          calculatedAmount: 0,
        },
      };
    }
    case CREATE_PAYMENT_SET_CARDID: {
      const { cardId } = action.payload;
      return {
        ...state,
        createPayment: {
          ...state.createPayment,
          cardId,
        },
      };
    }
    case CREATE_PAYMENT_START: {
      return {
        ...state,
        createPayment: {
          ...state.createPayment,
          isPaymentCreating: true,
          errorMsg: '',
        },
      };
    }
    case CREATE_PAYMENT_SUCCEED: {
      return {
        ...state,
        createPayment: {
          ...state.createPayment,
          isPaymentCreating: false,
          isPaymentCreated: true,
        },
      };
    }
    case CREATE_PAYMENT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        createPayment: {
          ...state.createPayment,
          isPaymentCreating: false,
          isPaymentCreated: false,
          errorMsg: error.message,
        },
      };
    }

    case CREATE_CARD_SET_CARDINFO: {
      const { cardInfo, ownerName } = action.payload;
      return {
        ...state,
        createCard: {
          cardInfo,
          ownerName,
          isCardCreated: false,
          isCardCreating: false,
          errorMsg: '',
        },
      };
    }
    case CREATE_CARD_START: {
      return {
        ...state,
        createCard: {
          ...state.createCard,
          isCardCreating: true,
        },
      };
    }
    case CREATE_CARD_SUCCEED: {
      return {
        ...state,
        createCard: {
          ...state.createCard,
          isCardCreating: false,
          isCardCreated: true,
          errorMsg: '',
        },
        billingInfo: {
          ...InitialState.billingInfo,
        },
      };
    }
    case CREATE_CARD_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        createCard: {
          ...state.createCard,
          isCardCreating: false,
          isCardCreated: false,
          errorMsg: error.message,
        },
      };
    }
    case GET_CARD_START: {
      return {
        ...state,
        getCards: {
          isCardFetching: true,
          isCardFetched: false,
          errorMsg: '',
        },
      };
    }
    case GET_CARD_SUCCEED: {
      const { cards } = action.payload;
      return {
        ...state,
        cards,
        getCards: {
          ...state.getCards,
          isCardFetching: false,
          isCardFetched: true,
        },
      };
    }
    case GET_CARD_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        getCards: {
          ...state.getCards,
          isCardFetching: false,
          isCardFetched: false,
          errorMsg: error.message,
        },
      };
    }
    case GET_TXHISTORIES_START: {
      return {
        ...state,
        getTxHistories: {
          isTxHistoriesFetching: true,
          isTxHistoriesFetched: false,
          errorMsg: '',
        },
      };
    }
    case GET_TXHISTORIES_SUCCEED: {
      const { txHistories } = action.payload;
      return {
        ...state,
        txHistories,
        getTxHistories: {
          isTxHistoriesFetching: false,
          isTxHistoriesFetched: true,
        },
      };
    }
    case GET_TXHISTORIES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        getTxHistories: {
          isTxHistoriesFetching: false,
          isTxHistoriesFetched: false,
          errorMsg: error.message,
        },
      };
    }
    case GET_GLOBAL_CONFIG: {
      const { data } = action.payload;
      return {
        ...state,
        config: {
          cashInFrozenDays: data.cash_in_frozen_days,
          relayTxFee: data.relay_tx_fee,
          usdcCashInFixAmountFee: data.usdc_credit_card_cash_in_fix_amount_fee,
          usdcCashInPercentageFee: data.usdc_credit_card_cash_in_percentage_fee,
          usdcCashOutFixAmountFeeERC: data.usdc_cash_out_fix_amount_fee_erc20,
          usdcCashOutPercentageFeeERC: data.usdc_cash_out_percentage_fee_erc20,
          circleFixFee: data.circle_fix_fee,
          circlePercentageFee: data.circle_percentage_fee,
          usdcDescriptionTw: data.usdc_description_tw,
          usdcDescriptionEn: data.usdc_description_en,
          cupOnceMaxExchange: data.cup_once_max_exchange,
          cupUsdcRatio: data.cup_usdc_raito || 100,
          relayerBackend: data.relayer_backend,
          version: data.version,
          usdcWireTransferCashInPercentageFee: data.usdc_wire_transfer_cash_in_percentage_fee,
          circleWalletAddressEth: data.circle_wallet_address_eth,
          vaultDepositFixAmountFee: data.vault_deposit_fix_amount_fee,
          maintenance: data.maintenance,
          // withdrawFeePremium: data.hippo_withdraw_fee_additional_percentage,
          binanceHttpUrl: data.binance_http_url,
          binanceWsUrl: data.binance_ws_url,
        },
      };
    }
    case GET_PAYMENT_CALCULATION: {
      const {
        pay_amount: payAmount,
        fee_cashflow: feeCashflow,
        fee_cappuu: feeCappuu,
      } = action.payload;
      return {
        ...state,
        paymentCalculation: {
          ...state.paymentCalculation,
          payAmount,
          feeCashflow,
          feeCappuu,
          isPaymentCalculating: false,
        },
      };
    }
    case GET_PAYMENT_CALCULATION_START: {
      return {
        ...state,
        paymentCalculation: {
          ...state.paymentCalculation,
          isPaymentCalculating: true,
          errorMsg: '',
        },
      };
    }

    case GET_PAYMENT_CALCULATION_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        paymentCalculation: {
          ...state.paymentCalculation,
          isPaymentCalculating: false,
          errorMsg: error.message,
        },
      };
    }

    case SAVE_TEMP_BILLING_INFO: {
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          ...action.payload,
        },
      };
    }

    case SEND_KYC_INFO_START: {
      return {
        ...state,
        kycInfo: {
          isSending: true,
          isSend: false,
          errorMsg: '',
        },
      };
    }

    case SEND_KYC_INFO_SUCCEED: {
      return {
        ...state,
        kycInfo: {
          isSending: false,
          isSend: true,
          errorMsg: '',
        },
      };
    }

    case SEND_KYC_INFO_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        kycInfo: {
          isSending: false,
          isSend: false,
          errorMsg: error.message,
        },
      };
    }

    case DELETE_CARD_START: {
      return {
        ...state,
        deleteCard: {
          isDeleting: true,
          errorMsg: '',
        },
      };
    }

    case DELETE_CARD_SUCCEED: {
      return {
        ...state,
        cards: state.cards.filter((card) => card.card_id !== action.payload),
        deleteCard: {
          isDeleting: false,
          errorMsg: '',
        },
      };
    }

    case DELETE_CARD_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        deleteCard: {
          isDeleting: false,
          errorMsg: error.message,
        },
      };
    }

    case CREATE_BANK_ACCOUNT_START: {
      return {
        ...state,
        createBankAccount: {
          isCreating: true,
          isCreated: false,
          errorMsg: '',
        },
      };
    }

    case CREATE_BANK_ACCOUNT_SUCCEED: {
      return {
        ...state,
        createBankAccount: {
          isCreating: false,
          isCreated: true,
          errorMsg: '',
        },
      };
    }

    case CREATE_BANK_ACCOUNT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        createBankAccount: {
          isCreating: false,
          isCreated: false,
          errorMsg: error.message,
        },
      };
    }

    case GET_BANK_ACCOUNT_START: {
      return {
        ...state,
        bankAccount: {
          isFetching: true,
          isFetched: false,
          errorMsg: '',
          account: [],
        },
      };
    }

    case GET_BANK_ACCOUNT_SUCCEED: {
      return {
        ...state,
        bankAccount: {
          isFetching: false,
          isFetched: true,
          errorMsg: '',
          account: action.payload,
        },
      };
    }

    case GET_BANK_ACCOUNT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        bankAccount: {
          isFetching: false,
          isFetched: false,
          errorMsg: error.message,
          account: [],
        },
      };
    }

    case GET_WIRE_INSTRUCTION_START: {
      return {
        ...state,
        wireInstruction: {
          isFetching: true,
          isFetched: false,
          errorMsg: '',
          beneficiary: InitialState.wireInstruction.beneficiary,
          beneficiaryBank: InitialState.wireInstruction.beneficiaryBank,
        },
      };
    }

    case GET_WIRE_INSTRUCTION_SUCCEED: {
      return {
        ...state,
        wireInstruction: {
          isFetching: false,
          isFetched: true,
          beneficiary: action.payload.beneficiary,
          beneficiaryBank: {
            ...action.payload.beneficiaryBank,
            trackingRef: `${action.payload.trackingRef}*CAPPUU`,
          },
          errorMsg: '',
        },
      };
    }

    case GET_WIRE_INSTRUCTION_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        wireInstruction: {
          isFetching: false,
          isFetched: false,
          beneficiary: InitialState.wireInstruction.beneficiary,
          beneficiaryBank: InitialState.wireInstruction.beneficiaryBank,
          errorMsg: error.message,
        },
      };
    }

    case SAVE_TEMP_BANK_BILLING_INFO: {
      return {
        ...state,
        tempBankBillingInfo: {
          ...action.payload,
        },
      };
    }
    case GET_IDR_PAYMENT_START: {
      return {
        ...state,
        idrPayment: {
          isFetching: true,
          isFetched: false,
          payment: null,
          errorMsg: '',
        },
      };
    }
    case GET_IDR_PAYMENT_SUCCEED: {
      return {
        ...state,
        idrPayment: {
          isFetching: false,
          isFetched: true,
          payment: action.payload,
          errorMsg: '',
        },
      };
    }
    case GET_IDR_PAYMENT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        idrPayment: {
          isFetching: false,
          isFetched: false,
          payment: null,
          errorMsg: error.message,
        },
      };
    }
    case CREATE_IDR_PAYMENT_START: {
      return {
        ...state,
        createIdrPayment: {
          isCreating: true,
          isCreated: false,
          errorMsg: '',
        },
      };
    }
    case CREATE_IDR_PAYMENT_SUCCEED: {
      return {
        ...state,
        createIdrPayment: {
          isCreating: false,
          isCreated: true,
          errorMsg: '',
        },
      };
    }
    case CREATE_IDR_PAYMENT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        createIdrPayment: {
          isCreating: false,
          isCreated: false,
          errorMsg: error.message,
        },
      };
    }
    case CHECK_IDR_PAYMENT_START: {
      return {
        ...state,
        checkIdrPayment: {
          isChecking: true,
        },
      };
    }
    case CHECK_IDR_PAYMENT_SUCCEED: {
      return {
        ...state,
        checkIdrPayment: {
          isChecking: false,
        },
        idrPayment: {
          isFetching: false,
          isFetched: false,
          payment: action.payload,
          errorMsg: '',
        },
      };
    }
    case CHECK_IDR_PAYMENT_FAILED: {
      return {
        ...state,
        checkIdrPayment: {
          isChecking: false,
        },
      };
    }
    case GET_CASHFLOW_BACKENDS: {
      return {
        ...state,
        cashflowBackends: action.payload,
      };
    }
    case CANCEL_PAYMENT_START: {
      return {
        ...state,
        cancelPayment: {
          isCanceling: true,
          isCanceled: false,
          errorMsg: '',
        },
      };
    }
    case CANCEL_PAYMENT_SUCCEED: {
      return {
        ...state,
        cancelPayment: {
          isCanceling: false,
          isCanceled: true,
          errorMsg: '',
        },
      };
    }
    case CANCEL_PAYMENT_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        cancelPayment: {
          isCanceling: false,
          isCanceled: false,
          errorMsg: error.message,
        },
      };
    }
    case CLEAN_PAYMENT: {
      return {
        ...state,
        idrPayment: {
          isFetching: false,
          isFetched: false,
          payment: null,
          errorMsg: '',
        },
      };
    }
    case GET_SIMPLEX_CALCULATION_START: {
      return {
        ...state,
        simplexPayment: {
          isCalculating: true,
          isCalculated: false,
          isGettingUrl: false,
          price: 0,
          quoteId: '',
          url: '',
          errorMsg: '',
        },
      };
    }
    case GET_SIMPLEX_CALCULATION_SUCCEED: {
      // eslint-disable-next-line camelcase
      const { deposit_amount, extra_data } = action.payload;
      return {
        ...state,
        simplexPayment: {
          isCalculating: false,
          isCalculated: true,
          isGettingUrl: false,
          price: deposit_amount,
          quoteId: extra_data.quote_id,
          url: '',
          errorMsg: '',
        },
      };
    }
    case GET_SIMPLEX_CALCULATION_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        simplexPayment: {
          ...InitialState.simplexPayment,
          errorMsg: error.message,
        },
      };
    }
    case GET_SIMPLEX_URL_START: {
      return {
        ...state,
        simplexPayment: {
          ...state.simplexPayment,
          isGettingUrl: true,
          url: '',
        },
      };
    }
    case GET_SIMPLEX_URL_SUCCEED: {
      return {
        ...state,
        simplexPayment: {
          ...state.simplexPayment,
          isGettingUrl: false,
          url: action.payload.redirect_url,
        },
      };
    }
    case SELECT_CARD: {
      return {
        ...state,
        selectedCardIndex: action.payload,
      };
    }
    case GET_SIMPLEX_URL_FAILED: {
      return {
        ...state,
        simplexPayment: {
          ...state.simplexPayment,
          isGettingUrl: false,
          url: '',
        },
      };
    }
    case RESET_SIMPLEX: {
      return {
        ...state,
        simplexPayment: InitialState.simplexPayment,
      };
    }
    case GET_BONUS_CONFIG: {
      const { display_name: displayName, usd_raito: usdRaito } = action.payload;
      return {
        ...state,
        bonusConfig: {
          displayName,
          usdRaito,
        },
      };
    }
    case GET_VAULT_TX_START: {
      return {
        ...state,
        vaultTx: {
          isFetching: true,
          isFetched: false,
          tx: [],
        },
      };
    }
    case GET_VAULT_TX_SUCCESS: {
      return {
        ...state,
        vaultTx: {
          isFetching: false,
          isFetched: true,
          tx: action.payload,
        },
      };
    }
    case GET_VAULT_TX_FAILED: {
      return {
        ...state,
        vaultTx: {
          isFetching: false,
          isFetched: true,
          tx: [],
        },
      };
    }

    case GET_DEPOSIT_TXHISTORIES_START: {
      return {
        ...state,
        depositTx: {
          isFetching: true,
          isFetched: false,
          tx: [],
        },
      };
    }

    case GET_DEPOSIT_TXHISTORIES_SUCCEED: {
      return {
        ...state,
        depositTx: {
          isFetching: false,
          isFetched: true,
          tx: action.payload,
        },
      };
    }

    case GET_DEPOSIT_TXHISTORIES_FAILED: {
      return {
        ...state,
        depositTx: {
          isFetching: false,
          isFetched: true,
          tx: [],
        },
      };
    }

    case GET_WITHDRAW_TXHISTORIES_START: {
      return {
        ...state,
        withdrawTx: {
          isFetching: true,
          isFetched: false,
          tx: [],
        },
      };
    }

    case GET_WITHDRAW_TXHISTORIES_SUCCEED: {
      return {
        ...state,
        withdrawTx: {
          isFetching: false,
          isFetched: true,
          tx: action.payload,
        },
      };
    }

    case GET_WITHDRAW_TXHISTORIES_FAILED: {
      return {
        ...state,
        withdrawTx: {
          isFetching: false,
          isFetched: true,
          tx: [],
        },
      };
    }

    case GET_YIELD_TXHISTORIES_START: {
      return {
        ...state,
        yieldTx: {
          isFetching: true,
          isFetched: false,
          tx: [],
        },
      };
    }

    case GET_YIELD_TXHISTORIES_SUCCEED: {
      return {
        ...state,
        yieldTx: {
          isFetching: false,
          isFetched: true,
          tx: action.payload,
        },
      };
    }

    case GET_YIELD_TXHISTORIES_FAILED: {
      return {
        ...state,
        yieldTx: {
          isFetching: false,
          isFetched: true,
          tx: [],
        },
      };
    }

    case GET_SWAP_TXHISTORIES_START: {
      return {
        ...state,
        swapTx: {
          isFetching: true,
          isFetched: false,
          tx: [],
        },
      };
    }

    case GET_SWAP_TXHISTORIES_SUCCEED: {
      return {
        ...state,
        swapTx: {
          isFetching: false,
          isFetched: true,
          tx: action.payload,
        },
      };
    }

    case GET_SWAP_TXHISTORIES_FAILED: {
      return {
        ...state,
        swapTx: {
          isFetching: false,
          isFetched: true,
          tx: [],
        },
      };
    }

    default:
      break;
  }
  return state;
};
