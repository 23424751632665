export const CREATE_PAYMENT_SET_AMOUNT = 'CREATE_PAYMENT_SET_AMOUNT';
export const CREATE_PAYMENT_SET_CARDID = 'CREATE_PAYMENT_SET_CARDID';
export const CREATE_PAYMENT_START = 'CREATE_PAYMENT_START';
export const CREATE_PAYMENT_SUCCEED = 'CREATE_PAYMENT_SUCCEED';
export const CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED';
export const CREATE_CARD_SET_CARDINFO = 'CREATE_CARD_SET_CARDINFO';
export const CREATE_CARD_START = 'CREATE_CARD_START';
export const CREATE_CARD_SUCCEED = 'CREATE_CARD_SUCCEED';
export const CREATE_CARD_FAILED = 'CREATE_CARD_FAILED';
export const GET_CARD_START = 'GET_CARD_START';
export const GET_CARD_SUCCEED = 'GET_CARD_SUCCEED';
export const GET_CARD_FAILED = 'GET_CARD_FAILED';
export const GET_TXHISTORIES_START = 'GET_TXHISTORIES_START';
export const GET_TXHISTORIES_SUCCEED = 'GET_TXHISTORIES_SUCCEED';
export const GET_TXHISTORIES_FAILED = 'GET_TXHISTORIES_FAILED';
export const GET_GLOBAL_CONFIG = 'GET_GLOBAL_CONFIG';
export const GET_PAYMENT_CALCULATION = 'GET_PAYMENT_CALCULATION';
export const GET_PAYMENT_CALCULATION_START = 'GET_PAYMENT_CALCULATION_START';
export const GET_PAYMENT_CALCULATION_FAILED = 'GET_PAYMENT_CALCULATION_FAILED';
export const SAVE_TEMP_BILLING_INFO = 'SAVE_TEMP_BILLING_INFO';
export const SEND_KYC_INFO_START = 'SEND_KYC_INFO';
export const SEND_KYC_INFO_SUCCEED = 'SEND_KYC_INFO_SUCCEED';
export const SEND_KYC_INFO_FAILED = 'SEND_KYC_INFO_FAILED';
export const DELETE_CARD_START = 'DELETE_CARD_START';
export const DELETE_CARD_SUCCEED = 'DELETE_CARD_SUCCEED';
export const DELETE_CARD_FAILED = 'DELETE_CARD_FAILED';
export const CREATE_BANK_ACCOUNT_START = 'CREATE_BANK_ACCOUNT_START';
export const CREATE_BANK_ACCOUNT_SUCCEED = 'CREATE_BANK_ACCOUNT_SUCCEED';
export const CREATE_BANK_ACCOUNT_FAILED = 'CREATE_BANK_ACCOUNT_FAILED';
export const GET_BANK_ACCOUNT_START = 'GET_BANK_ACCOUNT_START';
export const GET_BANK_ACCOUNT_SUCCEED = 'GET_BANK_ACCOUNT_SUCCEED';
export const GET_BANK_ACCOUNT_FAILED = 'GET_BANK_ACCOUNT_FAILED';
export const GET_WIRE_INSTRUCTION_START = 'GET_WIRE_INSTRUCTION_START';
export const GET_WIRE_INSTRUCTION_SUCCEED = 'GET_WIRE_INSTRUCTION_SUCCEED';
export const GET_WIRE_INSTRUCTION_FAILED = 'GET_WIRE_INSTRUCTION_FAILED';
export const SAVE_TEMP_BANK_BILLING_INFO = 'SAVE_TEMP_BANK_BILLING_INFO';
export const GET_IDR_PAYMENT_START = 'GET_IDR_PAYMENT_START';
export const GET_IDR_PAYMENT_SUCCEED = 'GET_IDR_PAYMENT_SUCCEED';
export const GET_IDR_PAYMENT_FAILED = 'GET_IDR_PAYMENT_FAILED';
export const CREATE_IDR_PAYMENT_START = 'CREATE_IDR_PAYMENT_START';
export const CREATE_IDR_PAYMENT_SUCCEED = 'CREATE_IDR_PAYMENT_SUCCEED';
export const CREATE_IDR_PAYMENT_FAILED = 'CREATE_IDR_PAYMENT_FAILED';
export const CHECK_IDR_PAYMENT_START = 'CHECK_IDR_PAYMENT_START';
export const CHECK_IDR_PAYMENT_SUCCEED = 'CHECK_IDR_PAYMENT_SUCCEED';
export const CHECK_IDR_PAYMENT_FAILED = 'CHECK_IDR_PAYMENT_FAILED';
export const GET_CASHFLOW_BACKENDS = 'GET_CASHFLOW_BACKENDS';
export const CANCEL_PAYMENT_START = 'CANCEL_PAYMENT';
export const CANCEL_PAYMENT_SUCCEED = 'CANCEL_PAYMENT_SUCCEED';
export const CANCEL_PAYMENT_FAILED = 'CANCEL_PAYMENT_FAILED';
export const CLEAN_PAYMENT = 'CLEAN_PAYMENT';
export const GET_TOKEN_LIST = 'GET_TOKEN_LIST';
export const SELECT_CARD = 'SELECT_CARD';
export const GET_SIMPLEX_CALCULATION = 'GET_SIMPLEX_CALCULATION';
export const GET_SIMPLEX_URL = 'GET_SIMPLEX_URL';
export const GET_SIMPLEX_CALCULATION_START = 'GET_SIMPLEX_CALCULATION_START';
export const GET_SIMPLEX_CALCULATION_SUCCEED = 'GET_SIMPLEX_CALCULATION_SUCCEED';
export const GET_SIMPLEX_CALCULATION_FAILED = 'GET_SIMPLEX_CALCULATION_FAILED';
export const GET_SIMPLEX_URL_START = 'GET_SIMPLEX_URL_START';
export const GET_SIMPLEX_URL_SUCCEED = 'GET_SIMPLEX_URL_SUCCEED';
export const GET_SIMPLEX_URL_FAILED = 'GET_SIMPLEX_URL_FAILED';
export const RESET_SIMPLEX = 'RESET_SIMPLEX';
export const GET_BONUS_CONFIG = 'GET_BONUS_CONFIG';
export const GET_VAULT_TX_START = 'GET_VAULT_TX_START';
export const GET_VAULT_TX_SUCCESS = 'GET_VAULT_TX_SUCCESS';
export const GET_VAULT_TX_FAILED = 'GET_VAULT_TX_FAILED';
export const GET_DEPOSIT_TXHISTORIES_START = 'GET_DEPOSIT_TXHISTORIES_START';
export const GET_DEPOSIT_TXHISTORIES_SUCCEED = 'GET_DEPOSIT_TXHISTORIES_SUCCEED';
export const GET_DEPOSIT_TXHISTORIES_FAILED = 'GET_DEPOSIT_TXHISTORIES_FAILED';
export const GET_WITHDRAW_TXHISTORIES_START = 'GET_WITHDRAW_TXHISTORIES_START';
export const GET_WITHDRAW_TXHISTORIES_SUCCEED = 'GET_WITHDRAW_TXHISTORIES_SUCCEED';
export const GET_WITHDRAW_TXHISTORIES_FAILED = 'GET_WITHDRAW_TXHISTORIES_FAILED';
export const GET_YIELD_TXHISTORIES_START = 'GET_YIELD_TXHISTORIES_START';
export const GET_YIELD_TXHISTORIES_SUCCEED = 'GET_YIELD_TXHISTORIES_SUCCEED';
export const GET_YIELD_TXHISTORIES_FAILED = 'GET_YIELD_TXHISTORIES_FAILED';
export const GET_SWAP_TXHISTORIES_START = 'GET_SWAP_TXHISTORIES_START';
export const GET_SWAP_TXHISTORIES_SUCCEED = 'GET_SWAP_TXHISTORIES_SUCCEED';
export const GET_SWAP_TXHISTORIES_FAILED = 'GET_SWAP_TXHISTORIES_FAILED';
