import {
  SET_CURRENT_WALLET,
  CLEAN_CURRENT_WALLET,
  GET_WALLETS_BALANCE_START,
  GET_WALLETS_BALANCE_SUCCEED,
  GET_WALLETS_BALANCE_FAILED,
  GET_FIREBASE_PROJECTS,
  GET_VAULTS_INFO,
  SET_PROJECTS_WITH_BALANCE,
  GET_ACTUAL_APY,
  START_GET_ACTUAL_APY,
} from '../Actions/walletActionType';
// import { currencyWallets } from '../../services/constans';

const InitialState = {
  wallet: null,
  balance: {
    unsettledUsdcBalance: {
      erc20: 0,
    },
    userWallet: {
      erc20: 0,
    },
    tokenWallets: [],
    // currencyWallets,
    vaults: [],
  },
  usdcApr: 0,
  usdToTwdRate: 28,
  getUsdcBalance: {
    isFetching: false,
    isFetched: false,
    errorMsg: '',
  },
  news: [],
  projects: [],
  projectsWithBalance: [],
  projectsWithBalanceIsFetched: false,
  deposit: {
    isDepositing: false,
    isDeposited: false,
    errorMsg: '',
    isGettingFee: false,
    feeErrorMsg: '',
    fee: 0,
  },
  withdraw: {
    isWithdrawing: false,
    isWithdrawed: false,
    errorMsg: '',
    isGettingFee: false,
    feeErrorMsg: '',
    fee: 0,
  },
  vaultsInfo: [],
  solana: {
    address: '',
    account: null,
  },
  actualApyIsFetched: false,
  actualApy: [],
};

export default (state = InitialState, action) => {
  switch (action.type) {
    case SET_CURRENT_WALLET: {
      const { wallet } = action.payload;
      return {
        ...state,
        wallet,
      };
    }

    case CLEAN_CURRENT_WALLET: {
      return InitialState;
    }

    case GET_WALLETS_BALANCE_START: {
      return {
        ...state,
        getUsdcBalance: {
          isFetching: true,
          isFetched: false,
          errorMsg: '',
        },
      };
    }
    case GET_WALLETS_BALANCE_SUCCEED: {
      // eslint-disable-next-line no-unused-vars
      const { data, tokenList } = action.payload;
      const {
        // unsettled_usdc_balance: unsettledUsdcBalance,
        // user_wallet: userWallet,
        wallets,
        yield_products: vaults,
      } = data;

      // eslint-disable-next-line array-callback-return
      // const updatedCurrencyWallets = currencyWallets.map((currencyWallet) => {
      //   const target = wallets.filter(
      //     (wallet) => wallet.currency === currencyWallet.symbol.toLowerCase(),
      //   );
      //   if (target.length > 0) {
      //     return {
      //       ...currencyWallet,
      //       balance: target[0].balance,
      //       usableBalance: target[0].usable_balance,
      //     };
      //   }
      //   return currencyWallet;
      // });
      // const updatedTokenWallets = tokenList.map((tokenWallet) => {
      //   const target = wallets.filter(
      //     (wallet) => wallet.currency === tokenWallet.symbol.toLowerCase(),
      //   );
      //   if (target.length > 0) {
      //     return {
      //       ...tokenWallet,
      //       balance: target[0].balance,
      //       usableBalance: target[0].usable_balance,
      //     };
      //   }
      //   return tokenWallet;
      // });
      // const { languageCode } = RNLocalize.getLocales()[0];
      // if (languageCode !== 'id') {
      //     updatedCurrencyWallets.reverse();
      // }
      return {
        ...state,
        balance: {
          // unsettledUsdcBalance: unsettled_usdc_balance,
          // userWallet: user_wallet,
          // currencyWallets: updatedCurrencyWallets,
          tokenWallets: wallets,
          vaults,
        },
        getUsdcBalance: {
          isFetching: false,
          isFetched: true,
          errorMsg: '',
        },
      };
    }

    case GET_WALLETS_BALANCE_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        getUsdcBalance: {
          isFetching: false,
          isFetched: false,
          errorMsg: error,
        },
      };
    }
    case GET_FIREBASE_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
      };
    }

    case GET_VAULTS_INFO: {
      return {
        ...state,
        vaultsInfo: action.payload,
      };
    }

    case SET_PROJECTS_WITH_BALANCE: {
      return {
        ...state,
        projectsWithBalance: action.payload,
        projectsWithBalanceIsFetched: true,
      };
    }
    case START_GET_ACTUAL_APY: {
      return {
        ...state,
        actualApy: [],
        actualApyIsFetched: false,
      };
    }

    case GET_ACTUAL_APY: {
      return {
        ...state,
        actualApy: action.payload,
        actualApyIsFetched: true,
      };
    }

    default:
      break;
  }
  return state;
};
