import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useCookies } from 'react-cookie';

const PublicRoute = (props) => {
  const { exact, path, component: Component, isLogined, isFetched } = props;
  const [cookies] = useCookies(['Token']);

  const render = () => {
    let action;
    if (isFetched && isLogined) {
      action = <Redirect to={{ pathname: '/portfolio' }} />;
    } else if ((isFetched && !isLogined) || (!isFetched && !cookies.Token)) {
      action = <Component />;
    }
    return action;
  };

  return <Route
      exact={exact}
      path={path}
      render={() => render()} />;
};

export default PublicRoute;

PublicRoute.propTypes = {
  exact: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  isLogined: PropTypes.bool.isRequired,
  isFetched: PropTypes.bool.isRequired,
};

PublicRoute.defaultProps = {
  exact: false,
};
