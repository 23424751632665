import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Pagination } from 'react-bootstrap';

import { H5, SmallBold, SmallRegular } from '../../styles/Fonts';

import Loader from '../../components/Loader';
import TxModal from './TxModal';
import NoRecord from '../../components/NoRecord';
import Colors from '../../styles/Colors';

const statusText = {
  success: 'Succeed',
  failed: 'Failed',
  pending: 'Processing',
  created: 'Created',
};

const depositMethod = {
  credit_card: 'Credit Card',
  wire_transfer: 'Wire Transfer',
  virtual_atm: 'Bank Transfer',
  crypto: 'Crypto',
};

const TransactionHistory = () => {
  const depositTx = useSelector((state) => state.payment.depositTx.tx);
  const withdrawTx = useSelector((state) => state.payment.withdrawTx.tx);
  const yieldTx = useSelector((state) => state.payment.yieldTx.tx);
  const swapTx = useSelector((state) => state.payment.swapTx.tx);
  const depositTxIsFetched = useSelector((state) => state.payment.depositTx.isFetched);
  const withdrawTxIsFetched = useSelector((state) => state.payment.withdrawTx.isFetched);
  const yieldTxIsFetched = useSelector((state) => state.payment.yieldTx.isFetched);
  const swapTxIsFetched = useSelector((state) => state.payment.swapTx.isFetched);

  const [selectedTxType, setSelectedTxType] = useState('');
  const [txList, setTxList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [txDetail, setTxDetail] = useState({});

  const [activePage, setActivePage] = useState(1);
  const [pagesNum, setPagesNum] = useState(0);

  useEffect(() => {
    if (depositTxIsFetched && selectedTxType === '') {
      setSelectedTxType('deposit');
    }
  }, [depositTxIsFetched]);

  const formatNum = (num, maxDigit) => {
    return parseFloat(num)?.toLocaleString(undefined, {
      maximumFractionDigits: maxDigit || undefined,
    });
  };

  const getTime = (time) => {
    return new Date(time * 1000)?.toLocaleString('default', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });
  };

  const list = {
    deposit: depositTx.map((tx) => ({
      ...tx,
      id: tx.payment_id,
      title: `Deposit ${tx.dest_wallet?.currency?.toUpperCase()}`,
      displayDate: tx.time_readable?.slice(0, 10),
      displayTime: getTime(tx.time),
      displayAmount: `${formatNum(tx.deposit_amount)} ${tx.dest_wallet?.currency?.toUpperCase()}`,
      displayStatus: statusText[tx.status_display] || tx.status_display,
      displayMethod: depositMethod[tx.payment_type] || tx.payment_type?.replace('_', ' '),
      displayBankName: tx.payment_info?.bank_name || '',
      displayVirtualAccount: tx.payment_info?.bank_account_number || '',
      displayDueDate: tx.payment_info?.due_date
        ? `${tx.payment_info.due_date?.slice(0, 10)} ${tx.payment_info.due_date?.slice(11, 16)}`
        : '',
      displayCost: tx.payment_type !== 'crypto' ? `${formatNum(tx.amount)} ${tx.cashflow_currency?.toUpperCase()}` : '',
      displayReceived: `${formatNum(tx.net_amount)} ${tx.dest_wallet?.currency?.toUpperCase()}`,
    })),
    withdraw: withdrawTx.map((tx) => ({
      ...tx,
      id: tx.tx_id,
      title: `Withdraw ${tx.currency?.toUpperCase()}`,
      displayDate: tx.time_readable?.slice(0, 10),
      displayTime: getTime(tx.time),
      displayAmount: `${formatNum(tx.amount)} ${tx.currency?.toUpperCase()}`,
      displayStatus: statusText[tx.status] || tx.status,
      displayFee: `${tx.fee_cappuu} ${tx.currency?.toUpperCase()}`,
      displayReceived: `${tx.net_amount} ${tx.currency?.toUpperCase()}`,
      displayBlockchain: tx.chain_type_display,
      displayAddress: tx.to_address,
    })),
    yield: yieldTx.map((tx) => ({
      ...tx,
      id: tx.tx_id,
      title: `${tx.tx_type === 'deposit' ? 'Deposit into ' : 'Redeem from '}${tx.yield_product.name}`,
      displayDate: tx.create_time_readable?.slice(0, 10),
      displayTime: getTime(tx.create_time),
      displayAmount: `${formatNum(tx.amount + (tx.gov_amount || 0))} ${tx.currency?.toUpperCase()}`,
      displayStatus: statusText[tx.status] || tx.status,
      displayFee: tx.fee_cappuu > 0 ? `${tx.fee_cappuu} ${tx.currency?.toUpperCase()}` : '',
      displayReceived: `${tx.net_amount} ${tx.currency?.toUpperCase()}`,
    })),
    swap: swapTx.map((tx) => ({
      ...tx,
      id: tx.tx_id,
      title: `Swap ${tx.source_currency?.toUpperCase()} to ${tx.target_currency?.toUpperCase()}`,
      displayDate: tx.time_readable?.slice(0, 10),
      displayTime: getTime(tx.time),
      displayAmount: `${formatNum(tx.target_amount, 12)} ${tx.target_currency?.toUpperCase()}`,
      displayStatus: statusText[tx.status] || tx.status,
      displayPrice: `1 ${tx.target_currency?.toUpperCase()} = ${formatNum(tx.price, 12)} ${tx.source_currency?.toUpperCase()}`,
      displaySwapCost: `${formatNum(tx.source_amount, 12)} ${tx.source_currency?.toUpperCase()}`,
      displayFee: `${formatNum(tx.total_fee_amount, 12)} ${tx.target_currency?.toUpperCase()}`,
    })),
  };

  useEffect(() => {
    setTxList(list[selectedTxType]);
  }, [selectedTxType]);

  useEffect(() => {
    if (txList?.length > 0) {
      setPagesNum(Math.ceil(txList.length / 10));
    }
  }, [txList]);

  const handleNextPage = () => {
    if (activePage < pagesNum) {
      setActivePage(activePage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const items = [];
  for (let number = 1; number <= pagesNum; number += 1) {
    items.push(
        <Pagination.Item
            key={number}
            active={number === activePage}
            onClick={() => setActivePage(number)}>
            {number}
        </Pagination.Item>
    );
  }

  const handleTxTypeChange = (type) => {
    setSelectedTxType(type);
    setActivePage(1);
  };

  const onClickDetail = (tx) => {
    setShowModal(true);
    setTxDetail(tx);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
      <Container>
          <RowContainer>
              <Title>Transaction History</Title>
              <SwitchButtonContainer>
                  <LeftButton
                      disabled={!depositTxIsFetched}
                      isActive={selectedTxType === 'deposit'}
                      onClick={() => handleTxTypeChange('deposit')}>
                      Deposit
                  </LeftButton>
                  <Button
                      disabled={!withdrawTxIsFetched}
                      isActive={selectedTxType === 'withdraw'}
                      onClick={() => handleTxTypeChange('withdraw')}>
                      Withdraw
                  </Button>
                  <Button
                      disabled={!yieldTxIsFetched}
                      isActive={selectedTxType === 'yield'}
                      onClick={() => handleTxTypeChange('yield')}>
                      Yield
                  </Button>
                  <RightButton
                      disabled={!swapTxIsFetched}
                      isActive={selectedTxType === 'swap'}
                      onClick={() => handleTxTypeChange('swap')}>
                      Swap
                  </RightButton>
              </SwitchButtonContainer>
          </RowContainer>
          {txList && txList.length > 0 && (
          <>
              <HistoriesHeader>
                  <TxHeaderDate>Date</TxHeaderDate>
                  <TxHeaderTitle>Title</TxHeaderTitle>
                  <TxHeaderAmount>Amount</TxHeaderAmount>
                  <TxHeaderDetail>Detail</TxHeaderDetail>
              </HistoriesHeader>
              <TxHistoriesContainer>
                  {txList.slice(0 + (activePage - 1) * 10, 10 + (activePage - 1) * 10).map((tx, index) => (
                      <TxHistory
                          backgroundColor={index % 2 === 0}
                          key={tx.id}>
                          <TxDate>{tx.displayDate}</TxDate>
                          <TxTitle>{tx.title}</TxTitle>
                          <TxAmount>{tx.displayAmount}</TxAmount>
                          <TxDetail>
                              <DetailButton onClick={() => onClickDetail(tx)}>Detail</DetailButton>
                          </TxDetail>
                      </TxHistory>
            ))}
              </TxHistoriesContainer>
              <PaginationContainer>
                  <StyledPagination>
                      <Pagination.First onClick={() => setActivePage(1)} />
                      <Pagination.Prev onClick={handlePreviousPage} />
                      {items}
                      <Pagination.Next onClick={handleNextPage} />
                      <Pagination.Last onClick={() => setActivePage(pagesNum)} />
                  </StyledPagination>
              </PaginationContainer>
          </>
      )}
          {txList && txList.length === 0 && <NoRecord />}
          {!txList && <Loader isLoading />}
          <TxModal
              show={showModal}
              tx={txDetail}
              handleClose={handleClose} />
      </Container>
  );
};

export default TransactionHistory;

const Container = styled.div`
  width: 100%;
  background-color: ${Colors.gray1};
  padding: 16px;
  border-radius: 8px;
`;

const Title = styled(H5)`
  font-weight: 600;
`;

const Button = styled.button`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => (props.isActive ? Colors.gray6 : Colors.gray5)};
  padding: 6px 12px;
  border: ${(props) => (props.isActive ? '1px solid #7c7c7c' : `1px solid ${Colors.gray3}`)};
  position: ${(props) => (props.isActive ? 'relative' : 'static')};
  margin-left: -1px;
`;

const LeftButton = styled(Button)`
  border-radius: 4px 0 0 4px;
`;

const RightButton = styled(Button)`
  border-radius: 0 4px 4px 0;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SwitchButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 576px) {
    margin-top: 16px;
  }
`;

const HistoriesHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  margin-top: 16px;
`;

const TxDate = styled(SmallRegular)`
  width: 100px;
  display: flex;
  align-items: center;
  /* text-align: center; */
`;

const TxTitle = styled(SmallRegular)`
  width: 200px;
  flex-grow: 2;
  /* text-align: center; */
  margin-left: 16px;
  display: flex;
  align-items: center;
  @media (max-width: 576px) {
    display: none;
  }
`;

const TxAmount = styled(SmallBold)`
  width: 150px;
  flex-grow: 1;
  text-align: right;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: wrap;
`;

const TxDetail = styled(SmallRegular)`
  width: 80px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;

const TxHeaderDate = styled(TxDate)`
  font-weight: bold;
`;

const TxHeaderTitle = styled(TxTitle)`
  font-weight: bold;
`;

const TxHeaderAmount = styled(TxAmount)``;

const TxHeaderDetail = styled(TxDetail)`
  font-weight: bold;
`;

const TxHistoriesContainer = styled.div``;

const TxHistory = styled(HistoriesHeader)`
  background-color: ${(props) => (props.backgroundColor ? Colors.gray2 : '')};
  margin-top: 0px;
`;

const DetailButton = styled.button`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${Colors.gray6};
  padding: 1px 8px;
  background: ${Colors.gray1};
  border: 1px solid ${Colors.gray3};
  box-sizing: border-box;
  border-radius: 4px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

const StyledPagination = styled(Pagination)`
  flex-wrap: wrap;
  .page-item {
    margin-top: 8px;
  }
  .active {
    background-color: ${Colors.primary.default};
    border-color: ${Colors.primary.default};
    position: static;
  }
  .page-link {
    color: ${Colors.gray5};
  }
  .page-item.active .page-link {
    background-color: ${Colors.primary.default};
    border-color: ${Colors.primary.default};
    position: static;
  }
  a {
    position: static;
  }
`;
