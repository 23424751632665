import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import 'bootstrap/dist/css/bootstrap.min.css';

import { setProjectsWithBalance } from '../../redux/Actions/walletActionCreater';

import UserInfo from './UserInfo';
import WalletBalance from './WalletBalance';
import PortfolioPieChart from './PortfolioPieChart';
import ApyPerformance from './ApyPerformance';
import TransactionHistory from './TransactionHistory';
import VaultsDetail from './VaultsDetail';

const PortfolioPage = () => {
  const [cookies] = useCookies(['Token']);
  const vaults = useSelector((state) => state.wallet.balance.vaults);
  const projects = useSelector((state) => state.wallet.projects);
  const tokenWallets = useSelector((state) => state.wallet.balance.tokenWallets);
  const projectsWithBalance = useSelector((state) => state.wallet.projectsWithBalance);
  const allTokenPrice = useSelector((state) => state.market.allTokenPrice);
  const isFetched = useSelector((state) => state.wallet.getUsdcBalance.isFetched);
  const projectsWithBalanceIsFetched = useSelector((state) => state.wallet.projectsWithBalanceIsFetched);

  const [tokenBalance, setTokenBalance] = useState(0);
  const [projectBalance, setProjectBalance] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [projectProfit, setProjectProfit] = useState(0);

  const dispatch = useDispatch();

  const getPrice = (currency) => {
    const price = _.get(allTokenPrice, `${currency.toUpperCase()}.USDC`, _.get(allTokenPrice, `${currency.toUpperCase()}.USDT`, 0));
    return parseFloat(price);
  };

  useEffect(() => {
    if (vaults.length > 0 && projects.length > 0) {
      const originalProjects = projects;
      const newProjects = originalProjects.map((project) => {
        let balance = 0;
        let profit = 0;
        let profitRate = 0;
        let apy = 0;
        let totalDeposit = 0;
        let totalWithdraw = 0;
        let price = 0;
        switch (project.info.apy_source) {
          case 'manual':
            apy = project.info.target_apy;
            break;
          case 'internal_apy':
            apy = _.round((parseFloat(project.actual_apy[1]) - 1) * 100, 2);
            break;
          case 'external_apy':
            apy = project.apy;
            break;
          default:
            apy = project.apy;
            break;
        }
        const isLottery = _.get(project, 'type') === 'DeFi Magic Pool';
        const matchedData = vaults.filter((vault) => {
          // TODO: remove when Steaker in production
          return vault.yield_product.product_id === project.info.product_id;
        });
        if (matchedData.length > 0) {
          const {
            total_balance: balanceInUsd,
            total_deposit_amount: totalDepositAmount,
            total_withdraw_amount: totalWithdrawAmount,
          } = matchedData[0];

          balance = parseFloat(balanceInUsd);
          profit = balance - parseFloat(totalDepositAmount) + parseFloat(totalWithdrawAmount);
          profitRate = totalDepositAmount ? profit / parseFloat(totalDepositAmount) : 0;
          totalDeposit = totalDepositAmount;
          totalWithdraw = totalWithdrawAmount;
          price = getPrice(project.info?.token_currency);
        }
        const lotteryInfo = isLottery
          ? {
              // eslint-disable-next-line max-len
              winChance: _.round(
                (parseInt(project.info.total_deposit_balance, 10) / parseInt(project.poolTogetherData.numberOfTickets, 10)) *
                  parseInt(project.poolTogetherData.numberOfWinners, 10) *
                  100,
                4
              ),
              winPrize: `${parseInt(project.poolTogetherData.winPrize, 10)} USDⓢ / Week`,
              apy: parseInt((project.poolTogetherData.winPrize / parseInt(project.info.total_deposit_balance, 10)) * 52 * 100, 10),
            }
          : {};
        return {
          ...project,
          balance: _.round(balance, 6),
          profit: _.round(profit, 6),
          profitRate: _.round(profitRate * 100, 2),
          totalDeposit,
          totalWithdraw,
          apy,
          isLottery,
          ...lotteryInfo,
          price,
          estiBalance: price * balance,
        };
      });
      dispatch(setProjectsWithBalance(newProjects));
    }
  }, [vaults, projects]);

  const getEstimatedTokenBalance = () => {
    const estimatedTokenBalance = tokenWallets.reduce((previous, current) => {
      const price = _.get(
        allTokenPrice,
        `${current.currency.toUpperCase()}.USDC`,
        _.get(allTokenPrice, `${current.currency.toUpperCase()}.USDT`, 0)
      );
      return _.floor(parseFloat(previous) + parseFloat(price) * parseFloat(current.balance), 2);
    }, 0);
    return estimatedTokenBalance;
  };

  const getEstimatedYieldBalance = () => {
    const estimatedYieldBalance = projectsWithBalance.reduce((previous, current) => {
      return _.floor(parseFloat(previous) + parseFloat(current.estiBalance), 2);
    }, 0);
    return estimatedYieldBalance;
  };

  const getEstimatedYieldProfit = () => {
    const estimatedYieldProfit = projectsWithBalance.reduce((previous, current) => {
      const currency = current.info.token_currency.toUpperCase();
      const price = _.get(allTokenPrice, `${currency}.USDC`, _.get(allTokenPrice, `${currency}.USDT`, 0));
      return _.floor(parseFloat(previous) + parseFloat(price) * parseFloat(current.profit), 2);
    }, 0);
    return estimatedYieldProfit;
  };

  useEffect(() => {
    if (isFetched && allTokenPrice) {
      const balance = getEstimatedTokenBalance();
      setTokenBalance(balance);
    }
  }, [isFetched, allTokenPrice]);

  useEffect(() => {
    if (projectsWithBalanceIsFetched && allTokenPrice) {
      const balance = getEstimatedYieldBalance();
      const profit = getEstimatedYieldProfit();
      setProjectBalance(balance);
      setProjectProfit(profit);
    }
  }, [projectsWithBalanceIsFetched, allTokenPrice]);

  useEffect(() => {
    const total = projectBalance + tokenBalance;
    setTotalBalance(parseFloat(total));
  }, [projectBalance, tokenBalance]);

  return (
      <Container>
          <UserInfo />
          <RowContainer>
              <WalletBalance
                  totalBalance={totalBalance}
                  tokenBalance={tokenBalance}
                  projectBalance={projectBalance}
                  projectProfit={projectProfit}
                  dataIsFetched={projectsWithBalanceIsFetched}
        />
              <PortfolioPieChart
                  totalBalance={totalBalance}
                  tokenBalance={tokenBalance}
                  projectBalance={projectBalance}
                  projectProfit={projectProfit}
                  dataIsFetched={projectsWithBalanceIsFetched}
        />
          </RowContainer>
          <SubContainer>
              <ApyPerformance
                  token={cookies.Token}
                  projectsWithBalance={projectsWithBalance} />
          </SubContainer>
          <SubContainer>
              <VaultsDetail
                  token={cookies.Token}
                  projectsWithBalance={projectsWithBalance}
                  dataIsFetched={projectsWithBalanceIsFetched} />
          </SubContainer>
          <SubContainer>
              <TransactionHistory token={cookies.Token} />
          </SubContainer>
      </Container>
  );
};

export default PortfolioPage;

const Container = styled.div`
  width: 100%;
`;

const RowContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const SubContainer = styled.div`
  margin-top: 16px;
`;
