import React, { useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import { logoutMember } from '../redux/Actions/memberActionCreater';

import SideBar from './SideBar';
import Colors from '../styles/Colors';

const LayoutComponent = (props) => {
  const { component: Component } = props;
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['Token']);
  const [navbarIsOpen, setNavbarIsOpen] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const logout = async () => {
    await removeCookie('Token');
    dispatch(logoutMember());
    history.push('/login');
  };

  const onClick = () => {
    setNavbarIsOpen(!navbarIsOpen);
  };

  return (
      <Container>
          <NavbarContainer open={navbarIsOpen}>
              <SideBar
                  open={navbarIsOpen}
                  toggle={onClick}
                  logout={logout} />
          </NavbarContainer>
          <MainContentContainer open={navbarIsOpen}>
              <Component />
          </MainContentContainer>
      </Container>
  );
};

export default LayoutComponent;

LayoutComponent.propTypes = {
  component: PropTypes.elementType.isRequired,
};

const Container = styled.div`
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  background-color: ${Colors.gray2};
  overflow: hidden;
`;

const NavbarContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: ${(props) => (props.open ? '240px' : '64px')};
  height: calc(100vh - 32px);
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 16px;
  transition: all 0.5s ease;
  @media (max-width: 992px) {
    width: auto;
  }
`;

const MainContentContainer = styled.main`
  width: ${(props) => (props.open ? 'calc(100vw - 288px)' : 'calc(100vw - 112px)')};
  margin: 16px;
  margin-left: ${(props) => (props.open ? '272px' : '96px')};
  transition: all 0.5s ease;
  @media (max-width: 992px) {
    width: calc(100vw - 32px);
    margin-left: 16px;
    margin-top: 72px;
  }
`;
