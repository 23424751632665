const validateEmail = (input, setIsValid) => {
  const emailTest = new RegExp(
    /^(([.](?=[^.]|^))|[\w_%{|}#$~`+!?-])+@(?:[\w-]+\.)+[a-zA-Z.]{2,63}$/,
  );
  const emailIsValid = emailTest.test(input);
  if (!emailIsValid) {
    setIsValid(false);
  } else {
    setIsValid(true);
  }
  return emailIsValid;
};

const validatePassword = (input, setIsValid) => {
  const passwordregex8digits = new RegExp(/^.*(?=.{8,16}).*$/);
  const passwordIsValid = passwordregex8digits.test(input);
  if (!passwordIsValid) {
    setIsValid(false);
  } else {
    setIsValid(true);
  }
  return passwordIsValid;
};

const validateConfirmPassword = (password, confirmPassword, setIsValid) => {
  const isSame = password === confirmPassword;
  if (!isSame) {
    setIsValid(false);
  } else {
    setIsValid(true);
  }
  return isSame;
};

const validateTextInput = (text, setIsValid) => {
  const textIsValid = text.length > 0;
  if (!textIsValid) {
    setIsValid(false);
  } else {
    setIsValid(true);
  }
  return textIsValid;
};

const validateCodeInput = (code, setIsValid) => {
  const codeIsValid = code.length === 6;
  if (!codeIsValid) {
    setIsValid(false);
  } else {
    setIsValid(true);
  }
  return codeIsValid;
};

export {
  validateEmail,
  validatePassword,
  validateConfirmPassword,
  validateTextInput,
  validateCodeInput,
};
