import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import { PieChart } from 'react-minimal-pie-chart';
import { PropTypes } from 'prop-types';
import { H5, SmallRegular } from '../../styles/Fonts';
import NoRecord from '../../components/NoRecord';
import Loader from '../../components/Loader';
import Colors from '../../styles/Colors';

const PortfolioPieChart = (props) => {
  const { projectBalance, totalBalance, dataIsFetched } = props;

  const tokenWallets = useSelector((state) => state.wallet.balance.tokenWallets);
  const allTokenPrice = useSelector((state) => state.market.allTokenPrice);
  const tokenList = useSelector((state) => state.market.tokenList);

  const [pieChartData, setPieChartData] = useState([]);
  const [checkedData, setCheckedData] = useState(false);

  const parsePieChartData = () => {
    const data = tokenWallets
      .slice()
      .filter((item) => item.balance !== 0)
      .map((item) => {
        const price = _.get(
          allTokenPrice,
          `${item.currency.toUpperCase()}.USDC`,
          _.get(allTokenPrice, `${item.currency.toUpperCase()}.USDT`, 0)
        );
        const tokenColor = tokenList.filter((token) => item.currency.toUpperCase() === token.symbol)[0].color;
        return {
          ...item,
          population: price * item.balance,
          color: tokenColor,
        };
      });

    if (projectBalance > 0) {
      data.push({
        name: 'Yield Product',
        population: projectBalance,
        color: Colors.primary.default,
      });
    }

    const sortedData = data.slice().sort((a, b) => b.population - a.population);
    let parsedData;
    if (sortedData.length > 5) {
      const sum = sortedData.slice(5).reduce((prev, current) => prev + current.population, 0);
      parsedData = sortedData.slice(0, 5);
      parsedData.push({
        population: sum,
        name: 'Others',
        color: '#999999',
      });
    } else {
      parsedData = sortedData;
    }

    const newParsedData = [];
    parsedData.forEach((item) => {
      newParsedData.push({
        title: item.name || item.currency.toUpperCase(),
        value: item.population,
        color: item.color,
      });
    });

    setPieChartData(newParsedData);
    setCheckedData(true);
  };

  useEffect(() => {
    if (dataIsFetched) {
      parsePieChartData();
    }
  }, [dataIsFetched, projectBalance]);

  return (
      <Container>
          <Title>Portfolio</Title>
          {checkedData && pieChartData.length > 0 && (
          <MainContent>
              <PieChartContainer>
                  <PieChart
                      data={pieChartData}
                      lineWidth={25}
                      paddingAngle={0.5}
                      animate
                      radius={50}
                      labelStyle={{
                fontSize: '6px',
                fontFamily: 'sans-serif',
                fill: '#ffffff',
              }}
                      labelPosition={75}
            />
              </PieChartContainer>
              <LabelContainer>
                  {pieChartData.map((item) => (
                      <Label key={item.title}>
                          <LabelIcon color={item.color} />
                          <LabelText>{`${Math.round((item.value / totalBalance) * 100)}% ${item.title}`}</LabelText>
                      </Label>
            ))}
              </LabelContainer>
          </MainContent>
      )}
          {!dataIsFetched && <Loader isLoading />}
          {checkedData && pieChartData.length === 0 && <NoRecord />}
      </Container>
  );
};

PortfolioPieChart.propTypes = {
  projectBalance: PropTypes.number,
  totalBalance: PropTypes.number,
  dataIsFetched: PropTypes.bool,
};

PortfolioPieChart.defaultProps = {
  projectBalance: 0,
  totalBalance: 0,
  dataIsFetched: false,
};

export default PortfolioPieChart;

const Container = styled.div`
  width: 100%;
  background-color: ${Colors.gray1};
  padding: 16px;
  border-radius: 8px;
`;

const Title = styled(H5)`
  font-weight: 600;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
`;

const PieChartContainer = styled.div`
  width: 120px;
  height: 120px;
`;

const LabelContainer = styled.div`
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px 0;
`;

const LabelIcon = styled.div`
  border-radius: 100%;
  height: 12px;
  width: 12px;
  background-color: ${(props) => props.color};
`;

const LabelText = styled(SmallRegular)`
  margin-left: 8px;
`;
