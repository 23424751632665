import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { BeatLoader } from 'react-spinners';
import Colors from '../styles/Colors';

const Loader = (props) => {
  const { isLoading } = props;

  return (
      <LoaderContainer>
          <BeatLoader
              color={Colors.primary.default}
              loading={isLoading}
              size={15}
              margin={2} />
      </LoaderContainer>
  );
};

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

Loader.defaultProps = {
  isLoading: true,
};

export default Loader;

const LoaderContainer = styled.div`
  position: static;
  z-index: 0;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
