import React from 'react';
import styled from 'styled-components';
import notFoundImage from '../../images/not_found.png';
import Colors from '../../styles/Colors';

const NotFoundPage = () => (
    <Container>
        <ImageContainer>
            <Image src={notFoundImage} />
        </ImageContainer>
        <Text>Page Not Found</Text>
    </Container>
);

export default NotFoundPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  object-position: center;
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  object-fit: contain;
  object-position: center;
`;

const Text = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  color: ${Colors.gray6};
  margin: 48px 0;
`;
