import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { verifyMemberEmail, backToRegister, loginMember } from '../../redux/Actions/memberActionCreater';
import { validateCodeInput } from '../../utils/validates';

import CoinomoLogo from '../../images/coinomo.png';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { ErrorText, H5, SmallRegular } from '../../styles/Fonts';
import Colors from '../../styles/Colors';

const EmailVerifyPage = () => {
  const errorMsg = useSelector((state) => state.member.register.emailErrorMsg);
  const nonce = useSelector((state) => state.member.register.nonce);
  const emailIsVeried = useSelector((state) => state.member.register.isEmailVeried);
  const isLogined = useSelector((state) => state.member.login.isLogined);

  const [code, setCode] = useState('');
  const [codeIsValid, setCodeIsValid] = useState(true);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const sendCode = () => {
    const codeChecked = validateCodeInput(code, setCodeIsValid);
    if (codeChecked) {
      dispatch(verifyMemberEmail(nonce, code));
    }
  };

  const getBack = () => {
    dispatch(backToRegister());
    history.push('/register');
  };

  useEffect(() => {
    if (emailIsVeried) {
      dispatch(loginMember(location.state.email, location.state.password, code));
    }
  }, [emailIsVeried]);

  useEffect(() => {
    if (isLogined) {
      history.push('/portfolio');
    }
  }, [isLogined]);

  return (
      <Container>
          <MainContent>
              <Image src={CoinomoLogo} />
              <Title>Enter Email Verification Code</Title>
              <Instruction>{'A 6 Digits Verification code is sent to '}</Instruction>
              <Email>{location.state.email}</Email>
              <TextInput
                  type='emailVerify'
                  onChange={setCode}
                  validationFailed={!codeIsValid} />
              {!!errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
              <Button
                  label='Send'
                  type='primary'
                  callback={sendCode} />
              <Button
                  label='Back to Register'
                  type='default'
                  callback={getBack} />
          </MainContent>
      </Container>
  );
};

export default EmailVerifyPage;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContent = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 32px 16px;
  /* border: 1px solid ${Colors.gray3}; */
  border-radius: 8px;
`;

const Image = styled.img`
  width: 170px;
  height: 32px;
`;

const Title = styled(H5)`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const ErrorMsg = styled(ErrorText)`
  margin-top: 16px;
`;

const Instruction = styled(SmallRegular)`
  text-align: center;
  color: ${Colors.primary.default};
`;

const Email = styled(Instruction)`
  color: ${Colors.primary.default};
`;
