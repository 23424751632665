const Colors = {
  primary: {
    default: '#00CC99',
    dark: '#4C9476',
  },
  success: {
    light: '#F6FFED',
    default: '#52C41A',
  },
  danger: {
    light: '#FFF1F0',
    default: '#FF4D4F',
  },
  warning: {
    light: '#FFFBE6',
    default: '#FAAD14',
  },
  gray1: '#FFFFFF',
  gray2: '#F7F7F7',
  gray3: '#E0E0E0',
  gray4: '#B2B2B2',
  gray5: '#8C8C8C',
  gray6: '#323232',
  gray7: '#000000',
  blue: '#0d6efd',
};

export default Colors;
