import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { loginMember } from '../../redux/Actions/memberActionCreater';
import { validateCodeInput } from '../../utils/validates';

import CoinomoLogo from '../../images/coinomo.png';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { H5, ErrorText, SmallRegular } from '../../styles/Fonts';
import apple from '../../images/apple.png';
import google from '../../images/google_play.png';
import Colors from '../../styles/Colors';

const appleUrl = 'https://apps.apple.com/app/cappuu/id1516439838';
const googleUrl = 'https://play.google.com/store/apps/details?id=com.cappuu';

const TwoFAPage = () => {
  const [code, setCode] = useState('');
  const [codeIsValid, setCodeIsValid] = useState(true);
  const isLogined = useSelector((state) => state.member.login.isLogined);
  const errorMsg = useSelector((state) => state.member.login.errorMsg);

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const login = () => {
    const codeChecked = validateCodeInput(code, setCodeIsValid);
    if (codeChecked) {
      dispatch(loginMember(location.state.email, location.state.password, code));
    }
  };

  useEffect(() => {
    if (isLogined) {
      history.push('/portfolio');
    }
  }, [isLogined]);

  return (
      <Container>
          <MainContent>
              <Image src={CoinomoLogo} />
              <Title>Authentication</Title>
              <DownloadContainer>
                  <DownloadButtons>
                      <DownloadButton
                          href={appleUrl}
                          target='_blank'>
                          <Icon src={apple} />
                          <ButtonText>App Store</ButtonText>
                      </DownloadButton>
                      <DownloadButton
                          href={googleUrl}
                          target='_blank'>
                          <Icon src={google} />
                          <ButtonText>Google Play</ButtonText>
                      </DownloadButton>
                  </DownloadButtons>
                  <Text>
                      Before setting up, you need to download
                      <ColorText> Google Authenticator </ColorText>
                      to continue.
                  </Text>
              </DownloadContainer>
              <BottomContainer>
                  <Text>Enter authentication code generated by Google Authentication APP on your phone.</Text>
                  <TextInput
                      type='twoFAVerify'
                      onChange={setCode}
                      validationFailed={!codeIsValid} />
                  {!!errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
                  <Button
                      label='Send'
                      type='primary'
                      callback={login} />
              </BottomContainer>
          </MainContent>
      </Container>
  );
};

export default TwoFAPage;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContent = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 32px 16px;
  /* border: 1px solid ${Colors.gray3}; */
  border-radius: 8px;
`;

const Image = styled.img`
  width: 170px;
  height: 32px;
`;

const Title = styled(H5)`
  margin-top: 16px;
`;

const ErrorMsg = styled(ErrorText)`
  margin-top: 4px;
`;

const BottomContainer = styled.div`
  padding-top: 16px;
  border-top: 1px solid ${Colors.gray3};
`;

const DownloadContainer = styled.div`
  padding: 16px 0;
`;

const Text = styled(SmallRegular)`
  color: ${Colors.gray5};
`;

const ColorText = styled(SmallRegular)`
  color: ${Colors.primary.default};
`;

const DownloadButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const DownloadButton = styled.a`
  padding: 6px;
  min-width: 156px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Colors.gray3};
  border-radius: 4px;
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
`;

const ButtonText = styled(SmallRegular)`
  text-align: center;
  margin-left: 8px;
`;
