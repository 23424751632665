/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import activePortfolio from '../images/portfolio_active.png';
import inactivePortfolio from '../images/portfolio_inactive.png';
import activePlans from '../images/plans_active.png';
import inactivePlans from '../images/plans_inactive.png';
import activeNews from '../images/news_active.png';
import inactiveNews from '../images/news_inactive.png';
import activeAccount from '../images/account_active.png';
import inactiveAccount from '../images/account_inactive.png';

import { SmallRegular } from '../styles/Fonts';
import Colors from '../styles/Colors';

const targetPath = {
  portfolio: '/portfolio',
  plans: '/plans',
  news: '/news',
  account: '/account',
};

const navIcon = {
  portfolio: { active: activePortfolio, inactive: inactivePortfolio },
  plans: { active: activePlans, inactive: inactivePlans },
  news: { active: activeNews, inactive: inactiveNews },
  account: { active: activeAccount, inactive: inactiveAccount },
};

const navText = {
  portfolio: 'Portfolio Overview',
  plans: 'Plans & Returns',
  news: 'News & Notifications',
  account: 'Account Settings',
};

const NavItem = (props) => {
  const { navTarget, disabled, open } = props;
  const history = useHistory();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  const pushToPage = (page) => {
    history.push(page);
  };

  useEffect(() => {
    if (location.pathname === targetPath[navTarget]) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location]);

  const onClick = () => {
    if (!disabled) {
      pushToPage(targetPath[navTarget]);
    }
  };

  return (
      <Container
          onClick={onClick}
          isDisabled={disabled}>
          <Icon
              src={isActive ? navIcon[navTarget].active : navIcon[navTarget].inactive}
              open={open} />
          <Text
              isActive={isActive}
              open={open}>
              {navText[navTarget]}
          </Text>
      </Container>
  );
};

NavItem.propTypes = {
  navTarget: PropTypes.string.isRequired,
};

export default NavItem;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${(props) => (props.isDisabled ? 'inherit' : 'pointer')};
  margin-left: 2px;
  overflow: hidden;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
  margin: 16px;
  transition: all 0.5s ease;
`;

const Text = styled(SmallRegular)`
  color: ${(props) => (props.isActive ? Colors.primary.default : Colors.gray5)};
  opacity: ${(props) => (props.open ? 1 : 0)};
  white-space: nowrap;
  transition: all 0.5s ease;
  @media (max-width: 992px) {
    opacity: 1;
  }
`;
