import { combineReducers } from 'redux';
import memberReducer from './memberReducer';
import walletReducer from './walletReducer';
import marketReducer from './marketReducer';
import paymentReducer from './paymentReducer';

const appReducer = combineReducers({
  member: memberReducer,
  wallet: walletReducer,
  market: marketReducer,
  payment: paymentReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_MEMBER') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
