import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { registerMember } from '../../redux/Actions/memberActionCreater';
import { validateEmail, validatePassword, validateConfirmPassword, validateTextInput } from '../../utils/validates';

import CoinomoLogo from '../../images/coinomo.png';
import TextInput from '../../components/TextInput';
import Button from '../../components/Button';
import { ErrorText, H5, TinyRegular } from '../../styles/Fonts';
import Colors from '../../styles/Colors';

const RegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referCode, setReferCode] = useState('');
  const [nameIsValid, setNameIsValid] = useState(true);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [passwordIsValid, setPasswordIsValid] = useState(true);
  const [confirmPasswordIsValid, setConfirmPasswordIsValid] = useState(true);
  const isLogined = useSelector((state) => state.member.login.isLogined);
  const nonce = useSelector((state) => state.member.register.nonce);
  const errorMsg = useSelector((state) => state.member.register.errorMsg);

  const dispatch = useDispatch();
  const history = useHistory();

  const login = () => {
    history.push('/login');
  };

  const register = () => {
    const nameChecked = validateTextInput(name, setNameIsValid);
    const emailChecked = validateEmail(email, setEmailIsValid);
    const passwordChecked = validatePassword(password, setPasswordIsValid);
    const confirmPasswordChecked = validateConfirmPassword(password, confirmPassword, setConfirmPasswordIsValid);
    if (nameChecked && emailChecked && passwordChecked && confirmPasswordChecked) {
      dispatch(registerMember(name, email, password, referCode));
    }
  };

  useEffect(() => {
    if (nonce) {
      history.push({
        pathname: `/emailVerify/${nonce}`,
        state: { email, password },
      });
    }
  }, [nonce]);

  useEffect(() => {
    if (isLogined) {
      history.push('/portfolio');
    }
  }, [isLogined]);

  return (
      <Container>
          <MainContent>
              <RowContainer>
                  <Image src={CoinomoLogo} />
                  <Beta>Beta</Beta>
              </RowContainer>
              <Title>Register</Title>
              <TextInput
                  type='name'
                  onChange={setName}
                  validationFailed={!nameIsValid} />
              <TextInput
                  type='email'
                  onChange={setEmail}
                  validationFailed={!emailIsValid} />
              <TextInput
                  type='password'
                  onChange={setPassword}
                  validationFailed={!passwordIsValid} />
              <TextInput
                  type='confirmPassword'
                  onChange={setConfirmPassword}
                  validationFailed={!confirmPasswordIsValid} />
              <TextInput
                  type='referCode'
                  onChange={setReferCode} />
              {!!errorMsg && <ErrorMsg>{errorMsg}</ErrorMsg>}
              <Button
                  label='Register'
                  type='primary'
                  callback={register} />
              <Button
                  label='Login'
                  type='secondary'
                  callback={login} />
          </MainContent>
      </Container>
  );
};

export default RegisterPage;

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContent = styled.div`
  max-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 32px 16px;
  /* border: 1px solid ${Colors.gray3}; */
  border-radius: 8px;
`;

const Image = styled.img`
  width: 170px;
  height: 32px;
`;

const Title = styled(H5)`
  margin-top: 16px;
`;

const ErrorMsg = styled(ErrorText)`
  margin-top: 16px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Beta = styled(TinyRegular)`
  margin-top: 6px;
  margin-left: 8px;
  color: ${Colors.gray1};
  background: ${Colors.blue};
  border-radius: 30px;
  padding: 3px 9px;
`;
