import { GET_ALL_TOKEN_PRICE, GET_TOKEN_HISTORY } from './marketActionType';
import { cappuuGetAllTokenPrice, coinomoGetWalletTx, coinomoGetCashInTx } from '../../utils/api';

export const getAllTokenPrice = (token) => async (dispatch) => {
  try {
    const result = await cappuuGetAllTokenPrice(token);
    dispatch({ type: GET_ALL_TOKEN_PRICE, payload: result });
  } catch (error) {
    console.log(error);
  }
};

export const getTokenHistory = (token, currency) => async (dispatch) => {
  try {
    const walletResult = await coinomoGetWalletTx(token, currency);
    const cashInResult = await coinomoGetCashInTx(token, currency);

    const buy = [];
    const sell = [];
    const deposit = cashInResult;
    const withdraw = [];
    // eslint-disable-next-line array-callback-return
    walletResult.map((item) => {
      if (item.wallet_tx_type === 'withdraw_from_wallet') {
        withdraw.push(item);
      } else if (item.wallet_tx_type === 'swap_out') {
        sell.push(item);
      } else if (item.wallet_tx_type === 'swap_in') {
        buy.push(item);
      } else if (item.wallet_tx_type === 'deposit_to_wallet') {
        // deposit.push(item);
      } else if (item.wallet_tx_type === 'payment') {
        // deposit.push(item);
      }
    });
    dispatch({
      type: GET_TOKEN_HISTORY,
      payload: {
        currency,
        buy,
        sell,
        deposit,
        withdraw,
      },
    });
  } catch (error) {
    console.log(error);
  }
};
