/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { PropTypes } from 'prop-types';
import { SmallBold, SmallRegular } from '../../styles/Fonts';
import Colors from '../../styles/Colors';

const TxModal = (props) => {
  const { show, tx, handleClose } = props;

  return (
      <Modal show={show}>
          <ModalHeader
              closeButton
              onHide={handleClose}>
              <ModalTitle>Transaction History</ModalTitle>
          </ModalHeader>
          <ModalBody>
              <SmallBold>{tx.title}</SmallBold>
              <TxRowContainer>
                  <TxRowTitle>Amount</TxRowTitle>
                  <TxRowValue>{tx.displayAmount}</TxRowValue>
              </TxRowContainer>
              <TxRowContainer>
                  <TxRowTitle>Status</TxRowTitle>
                  <TxRowValue>{tx.displayStatus}</TxRowValue>
              </TxRowContainer>
              <TxRowContainer>
                  <TxRowTitle>Time</TxRowTitle>
                  <TxRowValue>{tx.displayTime?.replace(',', '')}</TxRowValue>
              </TxRowContainer>
              {tx.displayMethod && (
              <TxRowContainer>
                  <TxRowTitle>Deposit Method</TxRowTitle>
                  <TxRowValue>{tx.displayMethod}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayCost && (
              <TxRowContainer>
                  <TxRowTitle>Cost</TxRowTitle>
                  <TxRowValue>{tx.displayCost}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayPrice && (
              <TxRowContainer>
                  <TxRowTitle>Exchange Rate</TxRowTitle>
                  <TxRowValue>{tx.displayPrice}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayFee && (
              <TxRowContainer>
                  <TxRowTitle>Network Fee</TxRowTitle>
                  <TxRowValue>{tx.displayFee}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayBlockchain && (
              <TxRowContainer>
                  <TxRowTitle>Blockchain</TxRowTitle>
                  <TxRowValue>{tx.displayBlockchain}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayAddress && (
              <TxRowContainer>
                  <TxRowTitle>Destination Address</TxRowTitle>
                  <TxRowValue>{tx.displayAddress}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayReceived && (
              <TxRowContainer>
                  <TxRowTitle>{tx.tx_type === 'deposit' ? 'Deposited Amount' : 'Received Amount'}</TxRowTitle>
                  <TxRowValue>{tx.displayReceived}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displaySwapCost && (
              <TxRowContainer>
                  <TxRowTitle>Cost</TxRowTitle>
                  <TxRowValue>{tx.displaySwapCost}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayBankName && (
              <TxRowContainer>
                  <TxRowTitle>Bank Name</TxRowTitle>
                  <TxRowValue>{tx.displayBankName}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayVirtualAccount && (
              <TxRowContainer>
                  <TxRowTitle>Virtual Account Number</TxRowTitle>
                  <TxRowValue>{tx.displayVirtualAccount}</TxRowValue>
              </TxRowContainer>
        )}
              {tx.displayDueDate && (
              <TxRowContainer>
                  <TxRowTitle>Due Date</TxRowTitle>
                  <TxRowValue>{tx.displayDueDate}</TxRowValue>
              </TxRowContainer>
        )}
          </ModalBody>
      </Modal>
  );
};

TxModal.propTypes = {
  show: PropTypes.bool,
  tx: PropTypes.object,
  handleClose: PropTypes.func,
};

TxModal.defaultProps = {
  show: false,
  tx: {},
  handleClose: () => {},
};

export default TxModal;

const ModalTitle = styled(Modal.Title)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${Colors.gray6};
`;

const ModalHeader = styled(Modal.Header)`
  border-bottom: 1px solid ${Colors.gray3};
`;

const ModalBody = styled(Modal.Body)`
  padding: 16px;
`;

const TxRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
`;

const TxRowTitle = styled(SmallRegular)`
  width: 100px;
  flex-grow: 1;
`;

const TxRowValue = styled(SmallBold)`
  text-align: right;
  margin-left: 10px;
  width: 100px;
  flex-grow: 1;
  word-wrap: break-word;
`;
