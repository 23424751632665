import {
  LOGIN_MEMBER_START,
  LOGIN_MEMBER_SUCCEED,
  LOGIN_MEMBER_FAILED,
  REGISTER_MEMBER_START,
  REGISTER_MEMBER_SUCCEED,
  REGISTER_MEMBER_FAILED,
  VERIFY_MEMBER_EMAIL_START,
  VERIFY_MEMBER_EMAIL_SUCCEED,
  VERIFY_MEMBER_EMAIL_FAILED,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCEED,
  GET_USER_PROFILE_FAILED,
  LOGOUT_MEMBER,
  CANNOT_GET_USER_PROFILE,
  BACK_TO_REGISTERPAGE,
  LOGIN_MEMBER_NEED_2FA,
} from '../Actions/memberActionType';

const initialState = {
  name: '',
  email: '',
  token: '',
  tokenError: false,
  login: {
    isLogining: false, // Login 1
    isLogined: false, // Login 2
    errorMsg: '',
    twoFAIsEnabled: false,
  },
  register: {
    isRegistering: false, // Register 1
    isRegistered: false, // Register 2: RegisterPage -> VerifyEmailPage
    isEmailVerifing: false, // Register 3
    isEmailVeried: false, // Register 4: VerifyEmailPage -> RegisterFinishPage
    nonce: '', // Nonce for email verification
    errorMsg: '', // Error message of registering
    emailErrorMsg: '', // Error message of email verification
  },
  profile: {
    isFetching: false,
    isFetched: false,
    errorMsg: '',
  },
};

export default function coinDetailReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_MEMBER_START:
      return {
        ...state,
        login: {
          ...state.login,
          isLogining: true,
          isLogined: false,
          errorMsg: '',
          twoFAIsEnabled: false,
        },
      };

    case LOGIN_MEMBER_SUCCEED: {
      const { token, member } = action.payload;
      const { name, email_address: email, backup_done: isBackedUp } = member;
      return {
        ...state,
        name,
        email,
        token,
        isBackedUp,
        login: {
          ...state.login,
          isLogining: false,
          isLogined: true,
          errorMsg: '',
        },
        register: {
          isRegistering: false, // Register 1
          isRegistered: false, // Register 2: RegisterPage -> VerifyEmailPage
          isEmailVerifing: false, // Register 3
          isEmailVeried: false, // Register 4: VerifyEmailPage -> RegisterFinishPage
          nonce: '', // Nonce for email verification
          errorMsg: '', // Error message of registering
          emailErrorMsg: '', // Error message of email verification
        },
        profile: {
          isFetching: false,
          isFetched: false,
          errorMsg: '',
        },
      };
    }

    case LOGIN_MEMBER_NEED_2FA: {
      return {
        ...state,
        login: {
          ...state.login,
          twoFAIsEnabled: true,
          isLogining: false,
        },
      };
    }

    case LOGIN_MEMBER_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        login: {
          ...state.login,
          isLogining: false,
          errorMsg: error.message,
        },
      };
    }

    case REGISTER_MEMBER_START: {
      return {
        ...state,
        register: {
          ...state.register,
          isRegistering: true,
          isRegistered: false,
        },
      };
    }

    case REGISTER_MEMBER_SUCCEED: {
      const { nonce } = action.payload;
      return {
        ...state,
        register: {
          ...state.register,
          isRegistering: false,
          isRegistered: true,
          nonce,
          errorMsg: '',
        },
      };
    }

    case REGISTER_MEMBER_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        register: {
          ...state.register,
          isRegistering: false,
          errorMsg: error.message,
        },
      };
    }

    case VERIFY_MEMBER_EMAIL_START: {
      return {
        ...state,
        register: {
          ...state.register,
          isEmailVerifing: true,
          isEmailVeried: false,
        },
      };
    }

    case VERIFY_MEMBER_EMAIL_SUCCEED: {
      return {
        ...state,
        register: {
          ...state.register,
          isEmailVerifing: false,
          isEmailVeried: true,
        },
      };
    }

    case VERIFY_MEMBER_EMAIL_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        register: {
          ...state.register,
          isEmailVerifing: false,
          isEmailVeried: false,
          emailErrorMsg: error.message,
        },
      };
    }

    case BACK_TO_REGISTERPAGE: {
      return initialState;
    }

    case GET_USER_PROFILE_START: {
      return {
        ...state,
        ethAddress: '',
        userId: '',
        userNumber: '',
        profile: {
          isFetching: true,
          isFetched: false,
          errorMsg: '',
        },
      };
    }

    case GET_USER_PROFILE_SUCCEED: {
      const {
        name,
        eth_address: ethAddress,
        user_id: userId,
        user_number: userNumber,
        applied_invite_code: appliedInviteCode,
        invite_code: inviteCode,
        bonus_balance: bonusBalance,
        backup_done: isBackedUp,
        email_address: email,
        bank_account_verified: bankAccountVerified,
        email_verified: emailVerified,
        identity_verified: identityVerified,
        sms_verified: smsVerified,
        selfie_verified: selfieVerified,
        totp_enable: totpEnable,
        is_demo: isDemo,
        user_type: userType,
        is_vip: isVip,
      } = action.payload;

      return {
        ...state,
        name,
        email,
        login: {
          isLogined: true,
        },
        ethAddress: ethAddress || '',
        userId,
        userNumber,
        appliedInviteCode,
        inviteCode,
        bonusBalance,
        isBackedUp,
        profile: {
          isFetching: false,
          isFetched: true,
          errorMsg: '',
        },
        bankAccountVerified,
        emailVerified,
        identityVerified,
        smsVerified,
        selfieVerified,
        twoFA: {
          ...state.twoFA,
          isEnable: totpEnable,
        },
        isDemo,
        userType,
        isVip,
      };
    }

    case GET_USER_PROFILE_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        profile: {
          isFetching: false,
          isFetched: true,
          errorMsg: error.message,
        },
      };
    }

    case CANNOT_GET_USER_PROFILE: {
      return {
        ...state,
        profile: {
          isFetching: false,
          isFetched: true,
        },
      };
    }

    case LOGOUT_MEMBER: {
      return initialState;
    }

    default:
      return state;
  }
}
