/* eslint-disable camelcase */
import _ from 'lodash';
import {
  GET_SWAP_PRICE_START,
  GET_SWAP_PRICE_SUCCEED,
  GET_SWAP_PRICE_FAILED,
  SWAP_TOKEN_START,
  SWAP_TOKEN_SUCCEED,
  SWAP_TOKEN_FAILED,
  RESET_SWAP_TOKEN_ERROR,
  GET_ALL_TOKEN_PRICE,
  GET_K_LINE_SUCCEED,
  GET_WITHDRAW_INFO,
  GET_TOKEN_HISTORY,
  UPDATE_TOKEN_SWAP_PRICE,
  GET_TOKEN_DESCRIPTION,
  GET_DEPOSIT_CHAIN,
  GET_DEPOSIT_ADDRESS,
  GET_DEPOSIT_INFO_START,
  GET_DEPOSIT_INFO_FAILED,
  GET_ALL_TOKEN_K_LINE,
} from '../Actions/marketActionType';
import { GET_TOKEN_LIST } from '../Actions/paymentActionType';

const InitialState = {
  swapTokenPrice: {
    askPrice: 0,
    bidPrice: 0,
    isFetching: false,
    isFetched: false,
    kLine: [],
  },
  swapToken: {
    isSwaping: false,
    isSwaped: false,
    errorMsg: '',
  },
  allTokenPrice: null,
  allTokenKLine: {},
  withdrawInfo: {},
  depositInfo: {
    isFetching: false,
    isFetched: false,
  },
  tokenHistory: {},
  allTokenSwapPrice: {},
  allTokenDescription: {},
  tokenList: [],
};

export default (state = InitialState, action) => {
  switch (action.type) {
    case GET_SWAP_PRICE_START: {
      return {
        ...state,
        swapTokenPrice: {
          askPrice: 0,
          bidPrice: 0,
          isFetching: true,
          isFetched: false,
        },
      };
    }
    case GET_SWAP_PRICE_SUCCEED: {
      const {
        premium_ask_price, premium_bid_price, min_source_amount, min_target_amount,
      } = action.payload;
      return {
        ...state,
        swapTokenPrice: {
          askPrice: parseFloat(premium_ask_price),
          bidPrice: parseFloat(premium_bid_price),
          minSourceAmount: parseFloat(min_source_amount),
          minTargetAmount: parseFloat(min_target_amount),
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_SWAP_PRICE_FAILED: {
      return {
        ...state,
        swapTokenPrice: {
          ...state.swapTokenPrice,
          isFetching: false,
          isFetched: false,
        },
      };
    }
    case SWAP_TOKEN_START: {
      return {
        ...state,
        swapToken: {
          isSwaping: true,
          isSwaped: false,
          errorMsg: '',
        },
      };
    }
    case SWAP_TOKEN_SUCCEED: {
      return {
        ...state,
        swapToken: {
          isSwaping: false,
          isSwaped: true,
          errorMsg: '',
        },
      };
    }
    case SWAP_TOKEN_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        swapToken: {
          isSwaping: false,
          isSwaped: false,
          errorMsg: error.message,
        },
      };
    }
    case RESET_SWAP_TOKEN_ERROR: {
      return {
        ...state,
        swapToken: {
          isSwaping: false,
          isSwaped: false,
          errorMsg: '',
        },
      };
    }
    case GET_ALL_TOKEN_PRICE: {
      return {
        ...state,
        allTokenPrice: action.payload,
      };
    }
    case GET_K_LINE_SUCCEED: {
      const { currency, target, data } = action.payload;
      const newData = state.allTokenKLine;
      // const kLine = {
      //     [currency]: data,
      // };
      // newData[target] = kLine;
      newData[target] = {
        ...newData[target],
        [currency]: {
          ..._.get(newData[target], `${currency}`, {}),
          ...data,
        },
      };
      return {
        ...state,
        allTokenKLine: {
          ...newData,
        },
      };
    }
    case GET_WITHDRAW_INFO: {
      const { currency, data } = action.payload;
      return {
        ...state,
        withdrawInfo: {
          ...state.withdrawInfo,
          [currency]: data,
        },
      };
    }
    case GET_TOKEN_HISTORY: {
      const {
        currency, buy, sell, deposit, withdraw,
      } = action.payload;
      return {
        ...state,
        tokenHistory: {
          ...state.tokenHistory,
          [currency]: {
            buy,
            sell,
            withdraw,
            deposit,
          },
        },
      };
    }
    case UPDATE_TOKEN_SWAP_PRICE: {
      const {
        token, currency, askPrice, bidPrice, price,
      } = action.payload;
      return {
        ...state,
        allTokenSwapPrice: {
          ...state.allTokenSwapPrice,
          [token]: {
            ...state.allTokenSwapPrice[token],
            [currency]: {
              askPrice,
              bidPrice,
              price,
            },
          },
        },
      };
    }
    case GET_TOKEN_DESCRIPTION: {
      const { token, data } = action.payload;
      return {
        ...state,
        allTokenDescription: {
          ...state.allTokenDescription,
          [token]: data,
        },
      };
    }
    case GET_DEPOSIT_CHAIN: {
      const { token, data } = action.payload;
      return {
        ...state,
        depositInfo: {
          ...state.depositInfo,
          [token]: data,
        },
      };
    }
    case GET_DEPOSIT_ADDRESS: {
      const { token, data, chain } = action.payload;
      const availableChain = state.depositInfo[token].slice();
      const updatedData = availableChain.map((item) => {
        if (item.chain.toUpperCase() === chain.toUpperCase()) return { ...item, address: data };
        return item;
      });
      return {
        ...state,
        depositInfo: {
          ...state.depositInfo,
          [token]: updatedData,
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_DEPOSIT_INFO_START: {
      return {
        ...state,
        depositInfo: {
          ...state.depositInfo,
          isFetching: true,
          isFetched: false,
        },
      };
    }
    case GET_DEPOSIT_INFO_FAILED: {
      return {
        ...state,
        depositInfo: {
          ...state.depositInfo,
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_TOKEN_LIST: {
      return {
        ...state,
        tokenList: action.payload,
      };
    }
    case GET_ALL_TOKEN_K_LINE: {
      return {
        ...state,
        allTokenKLine: action.payload,
      };
    }
    default:
      break;
  }
  return state;
};
