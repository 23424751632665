import React from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import Colors from '../styles/Colors';

const Button = (props) => {
  const { label, callback, type } = props;
  const buttons = {
    primary: <PrimaryButton onClick={() => callback()}>{label}</PrimaryButton>,
    secondary: <SecondaryButton onClick={() => callback()}>{label}</SecondaryButton>,
    default: <DefaultButton onClick={() => callback()}>{label}</DefaultButton>,
  };

  return <Container>{buttons[type]}</Container>;
};

export default Button;

Button.propTypes = {
  label: PropTypes.string,
  callback: PropTypes.func,
  type: PropTypes.string,
};

Button.defaultProps = {
  label: '',
  callback: () => {},
  type: 'primary',
};

const Container = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const ButtonContainer = styled.button`
  width: 100%;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
`;

const PrimaryButton = styled(ButtonContainer)`
  background: ${Colors.primary.default};
  border: 1px solid ${Colors.primary.default};
  color: white;
`;

const SecondaryButton = styled(ButtonContainer)`
  background: ${Colors.gray1};
  border: 1px solid ${Colors.gray3};
`;

const DefaultButton = styled(ButtonContainer)`
  padding: 6px 0;
  color: ${Colors.gray5};
`;
