import {
  SET_CURRENT_WALLET,
  GET_WALLETS_BALANCE_START,
  GET_WALLETS_BALANCE_SUCCEED,
  GET_WALLETS_BALANCE_FAILED,
  GET_FIREBASE_PROJECTS,
  GET_VAULTS_INFO,
  SET_PROJECTS_WITH_BALANCE,
  GET_ACTUAL_APY,
  START_GET_ACTUAL_APY,
} from './walletActionType';

import {
  cappuuGetWalletsBalance,
  cappuuGetVaults,
  cappuuGetActualApy,
} from '../../utils/api';

export const setCurrentWallet = (wallet) => async (dispatch) => {
  dispatch({ type: SET_CURRENT_WALLET, payload: { wallet } });
};

/**
 * Get Proxy Wallet
 * @param {string} token
 * @returns {function} (dispatch) => {}
 */
export const getWalletsBalance = (token) => async (dispatch) => {
  dispatch({ type: GET_WALLETS_BALANCE_START });
  // const { market } = getState();
  try {
    const result = await cappuuGetWalletsBalance(token);
    dispatch({
      type: GET_WALLETS_BALANCE_SUCCEED,
      payload: {
        data: result,
        // tokenList: market.tokenList,
      },
    });
  } catch (error) {
    dispatch({ type: GET_WALLETS_BALANCE_FAILED, payload: { error } });
  }
};

export const getProjects = (token) => async (dispatch) => {
  try {
    const cappuuResult = await cappuuGetVaults(token);
    const resultByConfigMode = cappuuResult.map((project) => {
      const resultByLanguage = project.display_config.en;
      return {
        ...resultByLanguage,
        info: { ...project },
        apy: resultByLanguage.apy || 0,
        address: resultByLanguage.vault
          ? resultByLanguage.vault.Develop.address
          : '', // set config mode
        id: resultByLanguage.vault ? resultByLanguage.vault.Develop.id : '', // set config mode
      };
    });
    dispatch({ type: GET_FIREBASE_PROJECTS, payload: resultByConfigMode });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get info of USDC
 * @param {string} token
 */
export const getVaultsInfo = (token) => async (dispatch) => {
  try {
    const result = await cappuuGetVaults(token);
    dispatch({ type: GET_VAULTS_INFO, payload: result });
  } catch (error) {
    dispatch({ type: GET_VAULTS_INFO, payload: [] });
  }
};

export const setProjectsWithBalance = (data) => async (dispatch) => {
  dispatch({ type: SET_PROJECTS_WITH_BALANCE, payload: data });
};

export const getActualApy =
  (token, vaultId, period, dataAmount) => async (dispatch) => {
    dispatch({ type: START_GET_ACTUAL_APY });
    try {
      const result = await cappuuGetActualApy(
        token,
        vaultId,
        period,
        dataAmount
      );
      const reversedData = result.reverse();
      dispatch({ type: GET_ACTUAL_APY, payload: reversedData });
    } catch (error) {
      console.log(error);
    }
  };
