import {
  cappuuLogin,
  cappuuRegister,
  cappuuVerifyEmail,
  cappuuGetUserProfile,
} from '../../utils/api';

import {
  LOGIN_MEMBER_START,
  LOGIN_MEMBER_SUCCEED,
  LOGIN_MEMBER_FAILED,
  REGISTER_MEMBER_START,
  REGISTER_MEMBER_SUCCEED,
  REGISTER_MEMBER_FAILED,
  VERIFY_MEMBER_EMAIL_START,
  VERIFY_MEMBER_EMAIL_SUCCEED,
  VERIFY_MEMBER_EMAIL_FAILED,
  GET_USER_PROFILE_START,
  GET_USER_PROFILE_SUCCEED,
  GET_USER_PROFILE_FAILED,
  LOGOUT_MEMBER,
  CANNOT_GET_USER_PROFILE,
  BACK_TO_REGISTERPAGE,
  LOGIN_MEMBER_NEED_2FA,
} from './memberActionType';

const loginMember = (email, password, totpCode) => async (dispatch) => {
  dispatch({ type: LOGIN_MEMBER_START });
  try {
    const result = await cappuuLogin(email, password, totpCode);
    if (result && result === 'Please provide totp_code and call again.') {
      dispatch({ type: LOGIN_MEMBER_NEED_2FA });
    } else if (result && typeof result === 'object') {
      dispatch({ type: LOGIN_MEMBER_SUCCEED, payload: result });
    }
  } catch (error) {
    dispatch({ type: LOGIN_MEMBER_FAILED, payload: { error } });
  }
};

const registerMember = (id, email, password, inviteCode) => async (dispatch) => {
  dispatch({ type: REGISTER_MEMBER_START });
  try {
    const result = await cappuuRegister(id, email, password, inviteCode);
    dispatch({ type: REGISTER_MEMBER_SUCCEED, payload: result });
  } catch (error) {
    dispatch({ type: REGISTER_MEMBER_FAILED, payload: { error } });
  }
};

const verifyMemberEmail = (nonce, code) => async (dispatch) => {
  dispatch({ type: VERIFY_MEMBER_EMAIL_START });
  try {
    await cappuuVerifyEmail(nonce, code);
    dispatch({ type: VERIFY_MEMBER_EMAIL_SUCCEED });
  } catch (error) {
    dispatch({ type: VERIFY_MEMBER_EMAIL_FAILED, payload: { error } });
  }
};

const getUserProfile = (token) => async (dispatch) => {
  if (token) {
    dispatch({ type: GET_USER_PROFILE_START });
    try {
      const result = await cappuuGetUserProfile(token);
      dispatch({ type: GET_USER_PROFILE_SUCCEED, payload: result });
      // setAmplitideUserID(result.user_id, result.email_address);
    } catch (error) {
      dispatch({ type: GET_USER_PROFILE_FAILED, payload: { error } });
    }
  } else {
    dispatch({ type: CANNOT_GET_USER_PROFILE });
  }
};

const logoutMember = () => async (dispatch) => {
  dispatch({ type: LOGOUT_MEMBER });
};

const backToRegister = () => async (dispatch) => {
  dispatch({ type: BACK_TO_REGISTERPAGE });
};

export {
  loginMember,
  registerMember,
  verifyMemberEmail,
  getUserProfile,
  logoutMember,
  backToRegister,
};
