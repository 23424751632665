import React from 'react';
import styled from 'styled-components';

import noRecord from '../images/noRecord.png';
import { SmallRegular } from '../styles/Fonts';
import Colors from '../styles/Colors';

const NoRecord = () => (
    <NoRecordContainer>
        <NoRecordImage src={noRecord} />
        <NoRecordText>No Record</NoRecordText>
    </NoRecordContainer>
);

export default NoRecord;

const NoRecordContainer = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoRecordImage = styled.img`
  width: 60px;
  height: 60px;
`;

const NoRecordText = styled(SmallRegular)`
  margin-top: 16px;
  color: ${Colors.gray5};
`;
