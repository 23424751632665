import React, { useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import { ErrorText } from '../styles/Fonts';
import Colors from '../styles/Colors';

import eye from '../images/eye.png';
import eyeOff from '../images/eyeOff.png';
import email from '../images/email.png';
import lock from '../images/lock.png';
import user from '../images/user.png';
import percent from '../images/percent.png';

const inputIcon = {
  name: user,
  email,
  password: lock,
  confirmPassword: lock,
  referCode: percent,
  emailVerify: lock,
  twoFAVerify: lock,
};

const errorMsg = {
  name: 'Please provide your name',
  email: 'Invalid Email',
  password: 'At least 8 characters',
  confirmPassword: 'Inconsistent Password',
  referCode: '',
  emailVerify: 'Verification code should be 6 digits',
  twoFAVerify: 'Verification code should be 6 digits',
};

const placeholder = {
  name: 'Enter Your Name',
  email: 'Enter Your Email',
  password: 'At least 8 characters',
  confirmPassword: 'Enter Password Again',
  referCode: 'Enter Referral Code (Option)',
  emailVerify: 'Enter Email Verification Code',
  twoFAVerify: 'Enter 2FA Verification Code',
};

const TextInput = (props) => {
  const { onChange, type, validationFailed } = props;
  const [showPassword, setShowPassword] = useState(false);

  return (
      <Container>
          <InputContainer fail={validationFailed}>
              <Icon src={inputIcon[type]} />
              <Input
                  type={(type === 'password' || type === 'confirmPassword') && !showPassword ? 'password' : 'text'}
                  onChange={(e) => onChange(e.target.value)}
                  placeholder={placeholder[type]}
        />
              {(type === 'password' || type === 'confirmPassword') && (
              <Icon
                  src={showPassword ? eye : eyeOff}
                  onClick={() => setShowPassword(!showPassword)} />
        )}
          </InputContainer>
          {validationFailed && <ErrorMsg>{errorMsg[type]}</ErrorMsg>}
      </Container>
  );
};

export default TextInput;

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  validationFailed: PropTypes.bool,
};

TextInput.defaultProps = {
  validationFailed: false,
};

const Container = styled.div`
  width: 100%;
  margin-top: 16px;
`;

const InputContainer = styled.div`
  width: 100%;
  background: ${Colors.gray1};
  border: ${(props) => (props.fail ? `1px solid ${Colors.danger.default}` : `1px solid ${Colors.gray3}`)};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const Input = styled.input`
  height: 22px;
  flex-basis: 100px;
  flex-grow: 1;
  margin-left: 6px;
  border: 0px;
  outline: none;
`;

const ErrorMsg = styled(ErrorText)`
  margin-top: 4px;
`;
