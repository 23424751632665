import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { SmallRegular } from '../../styles/Fonts';
import selfie from '../../images/selfie.png';
import vip from '../../images/vip.png';
import help from '../../images/help.png';

const UserInfo = () => {
  const name = useSelector((state) => state.member.name);
  const isVip = useSelector((state) => state.member.isVip);

  return (
      <Container>
          <UserInfoContainer>
              <UserImage src={selfie} />
              <UserName>{`Hello, ${name}`}</UserName>
              {isVip && <VIPImage src={vip} />}
          </UserInfoContainer>
          <HelpContainer
              href={process.env.REACT_APP_CAPPUU_HELP_URL}
              target='_blank'>
              <HelpImage src={help} />
          </HelpContainer>
      </Container>
  );
};

export default UserInfo;

const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  display: felx;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const UserInfoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const UserImage = styled.img`
  height: 32px;
  width: 32px;
`;

const UserName = styled(SmallRegular)`
  margin-left: 8px;
`;

const VIPImage = styled.img`
  height: 26px;
  width: 42px;
  margin-left: 8px;
  margin-top: 4px;
`;

const HelpContainer = styled.a`
  width: 32px;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 992px) {
    display: none;
  }
`;

const HelpImage = styled.img`
  height: 22px;
  width: 22px;
  margin: 0 auto;
  cursor: pointer;
`;
