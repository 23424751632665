import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';
import VaultCard from './VaultCard';
import NoRecord from '../../components/NoRecord';

import { H5 } from '../../styles/Fonts';
import Loader from '../../components/Loader';

const VaultsDetail = (props) => {
  const { projectsWithBalance, dataIsFetched } = props;

  const [projects, setProjects] = useState([]);
  const [checkedData, setCheckedData] = useState(false);

  useEffect(() => {
    if (dataIsFetched) {
      const newProjects = projectsWithBalance.filter((project) => project.balance > 0);
      setProjects(newProjects);
      setCheckedData(true);
    }
  }, [dataIsFetched]);

  return (
      <Container>
          <Title>Portfolio Breakdown</Title>
          <Projects>
              {projects.map((project) => (
                  <ProjectDiv key={project.balance}>
                      <VaultCard project={project} />
                  </ProjectDiv>
        ))}
          </Projects>
          {!checkedData && <Loader isLoading />}
          {checkedData && projects.length === 0 && <NoRecord />}
      </Container>
  );
};

VaultsDetail.propTypes = {
  projectsWithBalance: PropTypes.array,
  dataIsFetched: PropTypes.bool,
};

VaultsDetail.defaultProps = {
  projectsWithBalance: [],
  dataIsFetched: false,
};

export default VaultsDetail;

const Container = styled.div`
  width: 100%;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
`;

const Title = styled(H5)`
  font-weight: 600;
`;

const Projects = styled.div`
  width: 100%;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px 16px;
  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectDiv = styled.div``;
